import React, {memo, useLayoutEffect, useRef, useState} from 'react';
import classnames from 'classnames';
import styles from './courtPicker.module.css';
import {courtComparator} from '../../utils/comparator';
import {Checkbox} from '../index';

const courtPickerItem = memo(
    ({
        courts,
        showMore,
        courtValues,
        label,
        onValueChanged,
        field,
        onShowMoreClick,
    }) => {
        const checkboxesRef = useRef();
        const [singleLine, setSingleLine] = useState(true);
        useLayoutEffect(() => {
            setSingleLine(checkboxesRef.current.clientHeight === 42);
        }, []);
        return (
            <div>
                {!!courts.length && (
                    <>
                        <hr
                            style={{
                                width: '100%',
                                borderColor: 'var(--blue-light)',
                            }}
                        />
                        <span>{label}:</span>
                    </>
                )}
                <div
                    className={classnames({
                        [styles.hidden]: !showMore,
                    })}
                >
                    <div ref={checkboxesRef} className={styles.itemContainer}>
                        {courts.sort(courtComparator).map((court, index) => (
                            <Checkbox
                                key={court.name}
                                checked={courtValues[index]}
                                onChange={onValueChanged(field, index)}
                                id={court.name}
                                label={court.name}
                            />
                        ))}
                    </div>
                </div>
                {!!courts.length && !singleLine && (
                    <span
                        className={styles.showMoreButton}
                        onClick={onShowMoreClick}
                    >
                        Zobacz {showMore ? 'mniej' : 'więcej'}
                    </span>
                )}
            </div>
        );
    },
);

export default courtPickerItem;
