import React from 'react';
import Delete from '@mui/icons-material/Delete';
import styles from './listItem.module.css';
import {REGISTER} from '../../const/routes';
import {Table} from '../index';
import {getAddressInfo} from '../../utils/register';
import {Tooltip} from '@mui/material';
import NoteIcon from '@mui/icons-material/Note';

const ListItem = ({list, onRegisterDelete}) => {
    const onDelete = (event, id) => {
        event.preventDefault();
        event.stopPropagation();
        onRegisterDelete(id);
    };
    return (
        <Table
            headers={['', 'Numer księgi', 'Właściciel', 'Miejscowość', 'Adres']}
        >
            {list.registers.map(register => (
                <Table.Row
                    key={register.id}
                    hover
                    style={{
                        cursor: 'pointer',
                    }}
                    to={REGISTER(register.id)}
                >
                    {register.note && (
                        <Tooltip title={register.note}>
                            <NoteIcon />
                        </Tooltip>
                    )}
                    <div className={styles.center}>
                        <Delete
                            onClick={event => onDelete(event, register.id)}
                            className={styles.deleteButton}
                        />
                        {register.id}
                    </div>
                    {register.owners?.map(({owner}) => owner).join(', ')}
                    {register.position?.town || ''}
                    {getAddressInfo(register)}
                </Table.Row>
            ))}
        </Table>
    );
};

export default ListItem;
