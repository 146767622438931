const polishLetters = Object.entries({
    ą: 'a',
    ć: 'c',
    ę: 'e',
    ó: 'o',
    ń: 'n',
    ł: 'l',
    ś: 's',
    ź: 'z',
    ż: 'z',
});

export const normalizeStr = str => {
    return polishLetters
        .reduce(
            (result, [polish, normalized]) =>
                result.replace(new RegExp(polish, 'gi'), normalized),
            str,
        )
        .toUpperCase();
};
