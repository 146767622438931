import React, {useEffect, useMemo, useState} from 'react';
import styles from './Notes.module.css';
import {Indicator, Table, TextField} from '../../component';
import HTTPService from '../../service/HTTPService';
import {logNetworkError} from '../../utils/error';
import {REGISTER} from '../../const/routes';
import {normalizeStr} from '../../utils/string';

const NotesPage = () => {
    const [notes, setNotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState('');

    useEffect(() => {
        HTTPService.getNotes()
            .then(({data}) => {
                setNotes(data);
                setLoading(false);
            })
            .catch(logNetworkError);
    }, []);

    const filteredNotes = useMemo(() => {
        const normalizedQuery = normalizeStr(query).trim();
        return notes.filter(({content}) =>
            normalizeStr(content).includes(normalizedQuery),
        );
    }, [notes, query]);

    return loading ? (
        <Indicator />
    ) : (
        <div className={styles.container}>
            <div className={styles.table}>
                <TextField
                    placeholder={'Szukaj'}
                    value={query}
                    onChange={event => setQuery(event.target.value)}
                />
                <Table headers={['Nr księgi', 'Notatka']}>
                    {filteredNotes.map(note => (
                        <Table.Row
                            key={note._id}
                            to={REGISTER(note.registerId)}
                            hover
                            style={{cursor: 'pointer'}}
                        >
                            {note.registerId}
                            {note.content}
                        </Table.Row>
                    ))}
                </Table>
            </div>
        </div>
    );
};

export default NotesPage;
