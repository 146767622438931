import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button} from '@mui/material';
import {Checkbox, CourtPicker, TextField, Switch, DatePicker} from '../index';
import {DateTime} from 'luxon';

class UserForm extends Component {
    state = {
        username: '',
        password: '',
        courts: [],
        allCourts: false,
        reset2FaVerification: false,
        subscriptionEndsDate: null,
    };
    constructor(props) {
        super(props);
        this.state = {...this.state, ...props.initialUser};
        if (props.initialUser.subscriptionEndsDate) {
            this.state.subscriptionEndsDate = DateTime.fromISO(
                props.initialUser.subscriptionEndsDate,
            );
        }
    }

    onCourtsSwitchChange = event => {
        this.setState({allCourts: event.target.checked});
    };

    onCourtsCheck = courts => {
        this.setState({courts});
    };

    onResetVerificationChange = value => {
        this.setState({reset2FaVerification: value.target.checked});
    };

    onSubmit = event => {
        event.preventDefault();
        this.props.onSubmit(this.state);
    };

    onUsernameChange = event => {
        this.setState({username: event.target.value});
    };

    onPasswordChange = event => {
        this.setState({password: event.target.value});
    };

    onSuperuserChange = event => {
        this.setState({superuser: event.target.checked});
    };

    onDisabledChange = event => {
        this.setState({disabled: event.target.checked});
    };

    subscriptionEndsDateChange = date => {
        this.setState({
            subscriptionEndsDate: date?.startOf('day') || null,
        });
    };

    render() {
        const {submitLabel, initialUser, possibleCourts, onDelete, error} =
            this.props;
        const {
            username,
            password,
            allCourts,
            courts,
            reset2FaVerification,
            superuser,
            disabled,
        } = this.state;
        return (
            <form onSubmit={this.onSubmit} style={{width: 700}}>
                <TextField
                    style={{marginLeft: 10}}
                    onChange={this.onUsernameChange}
                    value={username}
                    label={'Nazwa użytkownika'}
                />
                <TextField
                    style={{marginLeft: 10}}
                    value={password}
                    onChange={this.onPasswordChange}
                    label={'Hasło'}
                />
                {initialUser && (
                    <div style={{display: 'flex'}}>
                        <Checkbox
                            onChange={this.onResetVerificationChange}
                            checked={reset2FaVerification}
                            label={'Zresetuj weryfikację 2 etapową'}
                            id={'twoFaVerification'}
                        />
                    </div>
                )}
                <div style={{display: 'flex'}}>
                    <Checkbox
                        onChange={this.onDisabledChange}
                        checked={disabled}
                        label={'Nieaktywny'}
                        id={'disabled'}
                    />
                </div>
                <DatePicker
                    label={'Data końca subskrypcji'}
                    value={this.state.subscriptionEndsDate}
                    onChange={this.subscriptionEndsDateChange}
                />
                <div>
                    <Switch
                        checked={superuser || false}
                        onChange={this.onSuperuserChange}
                        id={'superuser'}
                    />
                    <label htmlFor={'superuser'}>Superuser</label>
                </div>
                <div>
                    <Switch
                        checked={!!allCourts || superuser || false}
                        onChange={this.onCourtsSwitchChange}
                        id={'allCourts'}
                    />
                    <label htmlFor={'allCourts'}>
                        Dostęp do wszystkich sądów
                    </label>
                </div>
                {!allCourts && !superuser && (
                    <CourtPicker
                        style={{marginLeft: 10}}
                        onCourtsChange={this.onCourtsCheck}
                        noCourtModePicker
                        possibleCourts={possibleCourts}
                        initialSelectedCourts={courts}
                    />
                )}
                <Button style={{margin: 10}} variant="contained" type="submit">
                    {submitLabel}
                </Button>
                {initialUser && (
                    <Button
                        style={{margin: 10}}
                        onClick={onDelete}
                        variant="contained"
                    >
                        Usuń
                    </Button>
                )}
                <div style={{color: 'red', paddingTop: 10}}>{error}</div>
            </form>
        );
    }
}

UserForm.propTypes = {
    onDelete: PropTypes.any,
    submitLabel: PropTypes.any,
    initialUser: PropTypes.any,
    possibleCourts: PropTypes.array,
    onSubmit: PropTypes.any,
    error: PropTypes.string,
};

export default UserForm;
