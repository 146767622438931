import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import configureStore from './store/configureStore';
import {Provider} from 'react-redux';
import AuthService from './service/AuthService';

const store = configureStore();
const root = createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        {/*<React.StrictMode>*/}
        <AuthService>
            <App />
        </AuthService>
        {/*</React.StrictMode>*/}
    </Provider>,
);
