import React, {memo} from 'react';
import {Checkbox, DateRangePicker, Select, TextField} from '../../../component';

const MENTIONS_ITEMS = [
    {
        value: 'KOMO',
        label: 'Komornik',
    },
    {
        value: 'NOTA',
        label: 'Notariusz',
    },
    {
        value: 'OTHER',
        label: 'Inne',
    },
];

const chapterIII = memo(
    ({
        matchQuery,
        onQueryMatchFieldChangeBuilder,
        containsAnything,
        onContainsAnythingChange,
    }) => (
        <>
            <Checkbox
                checked={containsAnything}
                onChange={onContainsAnythingChange}
                id={'IIIcontainsAnything'}
                label={'Posiada jakikolwiek wpis'}
            />
            <TextField
                label={'Rodzaj wpisu'}
                id={'claimEntryType'}
                onChange={onQueryMatchFieldChangeBuilder}
                value={matchQuery.claimEntryType}
            />
            <TextField
                label={'Treść wpisu'}
                id={'claimEntryContent'}
                onChange={onQueryMatchFieldChangeBuilder}
                value={matchQuery.claimEntryContent}
            />
            <TextField
                label={'Osoba'}
                id={'claimOwners'}
                onChange={onQueryMatchFieldChangeBuilder}
                value={matchQuery.claimOwners}
            />
            <TextField
                label={'Imiona rodziców'}
                id={'claimOwnerParents'}
                onChange={onQueryMatchFieldChangeBuilder}
                value={matchQuery.claimOwnerParents}
            />
            <TextField
                label={'PESEL'}
                id={'claimOwnerPesel'}
                onChange={onQueryMatchFieldChangeBuilder}
                value={matchQuery.claimOwnerPesel}
            />
            <TextField
                label={'REGON'}
                id={'claimOwnerRegon'}
                onChange={onQueryMatchFieldChangeBuilder}
                value={matchQuery.claimOwnerRegon}
            />
            <TextField
                label={'Wzmianki'}
                id={'mentionsDescription'}
                onChange={onQueryMatchFieldChangeBuilder}
                value={matchQuery.mentionsDescription}
            />
            <DateRangePicker id={'mentionsDate'} label={'Data wzmianki'} />
            <Select
                id={'mentionsAuthors'}
                title={'Autor wzmianki'}
                value={matchQuery.mentionsAuthors}
                onChange={onQueryMatchFieldChangeBuilder}
                items={MENTIONS_ITEMS}
            />
        </>
    ),
);

export default chapterIII;
