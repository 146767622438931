export const courtComparator = (a, b) => {
    return getTransformedCourtName(a).localeCompare(getTransformedCourtName(b));
};

export const userComparator = (a, b) => {
    return a.username.localeCompare(b.username);
};

const getTransformedCourtName = court =>
    court.name.substr(0, 2) + court.name.substr(3, 1) + court.name.substr(2, 1);
