export const getAddressInfo = ({address}) => {
    let addressInfo = address?.street.join(', ') || '';
    if (address?.houseStringNumber) {
        addressInfo += `, ${address.houseStringNumber.join(', ')}`;
        if (address.apartmentStringNumber) {
            addressInfo += `/${address.apartmentStringNumber.join(', ')}`;
        }
    }
    return addressInfo;
};
