import React, {useEffect, useState} from 'react';
import styles from './Users.module.css';
import {Indicator, Table, DateRangePicker} from '../../component';
import HTTPService from '../../service/HTTPService';
import {logNetworkError} from '../../utils/error';
import {Button} from '@mui/material';
import {EDIT_USER, NEW_USER} from '../../const/routes';
import {useNavigate} from 'react-router-dom';
import {DateTime} from 'luxon';
import {userComparator} from '../../utils/comparator';
import classNames from 'classnames';

const UsersPage = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [subscriptionEndsDate, setSubscriptionEndsDate] = useState({
        from: null,
        to: null,
    });
    const navigate = useNavigate();

    const fetchUsers = subscriptionEndsDate => {
        setLoading(true);
        HTTPService.getAllUsers(
            subscriptionEndsDate?.from,
            subscriptionEndsDate?.to,
        )
            .then(({data}) => {
                setUsers(
                    data.users
                        .map(user => ({
                            ...user,
                            lastTokenRefreshDate: user.lastTokenRefreshDate
                                ? DateTime.fromISO(
                                      user.lastTokenRefreshDate,
                                  ).toFormat('HH:mm dd/LL/yyyy')
                                : '',
                            subscriptionEndsDate: user.subscriptionEndsDate
                                ? DateTime.fromISO(user.subscriptionEndsDate)
                                : null,
                            subscriptionEndsDateLabel: user.subscriptionEndsDate
                                ? DateTime.fromISO(
                                      user.subscriptionEndsDate,
                                  ).toFormat('HH:mm dd/LL/yyyy')
                                : '',
                        }))
                        .sort(userComparator),
                );
                setLoading(false);
            })
            .catch(logNetworkError);
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const getRole = user => {
        if (user.superuser) {
            return 'Superuser';
        } else {
            return user.allCourts ? 'Dostęp do wszystkich sądów' : 'Normalny';
        }
    };

    const onSubscriptionEndsDateRangeChanged = subscriptionEndsDate => {
        setSubscriptionEndsDate(subscriptionEndsDate);
        fetchUsers(subscriptionEndsDate);
    };

    return loading ? (
        <Indicator />
    ) : (
        <div className={styles.container}>
            <div className={styles.table}>
                <Button
                    variant="contained"
                    style={{margin: 10}}
                    onClick={() => navigate(NEW_USER)}
                >
                    Dodaj nowego użytkownika
                </Button>
                <DateRangePicker
                    label={'data końca supskrypcji'}
                    value={subscriptionEndsDate}
                    onChange={onSubscriptionEndsDateRangeChanged}
                    className={styles.subscriptionDatePicker}
                />
                <Table
                    headers={[
                        'Nazwa',
                        'Rola',
                        'Status 2fa',
                        'Data ostatniego zalogowania',
                        'Obejrzał zmiany',
                        'Koniec subskrypcji',
                    ]}
                >
                    {users.map(user => (
                        <Table.Row
                            key={user.id}
                            onClick={() => navigate(EDIT_USER(user.id))}
                            hover
                            style={{cursor: 'pointer'}}
                        >
                            {user.username}
                            {getRole(user)}
                            {user.twoFAValidated
                                ? 'Zweryfikowany'
                                : 'Niezweryfikowany'}
                            {user.lastTokenRefreshDate}
                            {user.changesChecked ? 'Tak' : 'Nie'}
                            <span
                                className={classNames({
                                    [styles.subscriptionDateSoonExpire]:
                                        user.subscriptionEndsDate
                                            ?.minus({month: 1})
                                            .diffNow() < 0,
                                    [styles.subscriptionDateExpired]:
                                        user.subscriptionEndsDate?.diffNow() <
                                        0,
                                })}
                            >
                                {user.subscriptionEndsDateLabel}
                            </span>
                        </Table.Row>
                    ))}
                </Table>
                <Button
                    variant="contained"
                    style={{margin: 10}}
                    onClick={() => navigate(NEW_USER)}
                >
                    Dodaj nowego użytkownika
                </Button>
            </div>
        </div>
    );
};

export default UsersPage;
