import {
    ALL_COURTS,
    POSSIBLE_COURTS_ONLY,
} from '../component/courtPicker/courtPicker';
import {cloneDeep, flatten, isEmpty} from 'lodash';

const exactFields = [
    'id',
    'owner',
    'parents',
    'regon',
    'pesel',
    'krs',
    'mentionsAuthors',
    'entryBasisSignature',
];

const wildcardFields = ['fieldNumbers', 'fieldIds'];
const boostingValues = {mentionsAuthors: 8, wholeRegister: 2};

export const toMatchQuery = fields =>
    Object.entries(fields)
        .filter(([_, currentValue]) => currentValue !== '')
        .reduce(
            (previousValue, [currentField, currentValue]) => ({
                ...previousValue,
                [currentField]: {
                    type: 'match',
                    value: currentValue,
                    options: {
                        exact: exactFields.includes(currentField),
                        wildcard: wildcardFields.includes(currentField),
                        ...(boostingValues[currentField]
                            ? {boost: boostingValues[currentField]}
                            : {}),
                    },
                },
            }),
            {},
        );

export const toEntryBasisQuery = (entryBasis, type, superuser) => {
    const mappedEntries = Object.entries(entryBasis)
        .filter(
            ([key, currentValue]) =>
                currentValue !== '' &&
                (key !== 'date' || currentValue?.from || currentValue?.to) &&
                (type !== 'differentBasis' || key !== 'headquarters') &&
                (type !== 'notarialAct' || superuser || key !== 'author'),
        )
        .map(([currentField, currentValue]) => {
            const fullField = `entryBasis${
                currentField.charAt(0).toUpperCase() + currentField.slice(1)
            }`;
            return [
                fullField,
                {
                    type: currentField === 'date' ? 'range' : 'match',
                    ...(currentField === 'date'
                        ? {
                              ...(currentValue.from
                                  ? {from: currentValue.from}
                                  : {}),
                              ...(currentValue.to ? {to: currentValue.to} : {}),
                          }
                        : {value: currentValue}),
                    options: {
                        exact: exactFields.includes(fullField),
                        wildcard: wildcardFields.includes(currentField),
                        ...(boostingValues[fullField]
                            ? {boost: boostingValues[fullField]}
                            : {}),
                    },
                },
            ];
        });

    return isEmpty(mappedEntries) || !type
        ? {}
        : {...Object.fromEntries(mappedEntries), entryBasisType: type};
};

export const buildTextQuery = fields => {
    const transformedFields = cloneDeep(fields);
    if (fields.id) {
        fields.id = fields.id.replace(/ /g, '');
        const parts = flatten(fields.id.split('-').map(p => p.split('/')));
        const index = parts.findIndex(part => !isNaN(Number(part)));
        parts[index] = ('00000000' + parts[index]).slice(-8);
        transformedFields.id = parts.join('-');
    }
    return transformedFields;
};

const fieldIsNotEmpty = field => field !== '' && field !== null;

export const toRangeQuery = fields =>
    Object.entries(fields)
        .filter(
            ([_, currentValue]) =>
                fieldIsNotEmpty(currentValue.to) ||
                fieldIsNotEmpty(currentValue.from),
        )
        .reduce(
            (previousValue, [currentField, currentValue]) => ({
                ...previousValue,
                [currentField]: {
                    type: 'range',
                    ...(currentValue.from ? {from: currentValue.from} : {}),
                    ...(currentValue.to ? {to: currentValue.to} : {}),
                },
            }),
            {},
        );

export const autofillRangeFields = fields => {
    const copy = cloneDeep(fields);
    ['houseNumber', 'apartmentNumber'].forEach(field => {
        if (copy[field].from && !copy[field].to) {
            copy[field].to = copy[field].from;
        }
    });
    return copy;
};

export const toFilterByCourtsQuery = (searchMode, courts) =>
    searchMode === POSSIBLE_COURTS_ONLY
        ? {
              courts: {values: courts.map(({name}) => name)},
          }
        : {};

export const toFilterByIOContentTypeQuery = chapterTypes => {
    if (!chapterTypes) {
        return null;
    }
    const result = Object.entries(chapterTypes)
        .filter(([_, value]) => value)
        .map(([field]) => field);
    return result.length === 3 ? null : result;
};

export const toFilterQuery = (name, value) => ({
    [name]: {
        type: 'filter',
        value,
    },
});

export const cleanForbiddenQueryFields = query => {
    const INDEXED_FULL_FIELDS = [
        'searchFull', // generic
        'searchActual',
        'courts',
        'ignoreSeen',
        'wholeRegister',
        'town', // Chapter IO
        'commune',
        'extractedApartmentsCount',
        'street',
        'fieldNumber',
        'fieldId',
        'landUsePurpose',
        'registrationPrecinct',
        'fieldNumbers',
        'fieldIds',
        'apartmentUsePurpose',
        'buildingUsePurpose',
        'houseNumber',
        'apartmentNumber',
        'size',
        'buildingsCount',
        'apartmentsCount',
        'fieldCount',
        'floors',
        'owner', // Chapter II
        'containsPerpetualUsers',
        'parents',
        'pesel',
        'regon',
        'ownersCount',
        'IVcontainsAnything', // Chapter IV
        'mortgageType',
        'mortgageInformation',
        'mortgageValue',
        'claim',
        'mortgageLegalRelationship',
        'mortgageOwner',
        'mortgageOwnerParents',
        'mortgageOwnerPesel',
        'mortgageOwnerRegon',
        'mortgageOwnerKRS',
        'mortgageCurrency',
        'mortgageAdministrator',
        'mortgageOwnerHeadquarters',
        'mortgageOwnerRole',
        'entryBasisType',
        'entryBasisTitle',
        'entryBasisSignature',
        'entryBasisDate',
        'entryBasisAuthor',
        'entryBasisHeadquarters',
    ];

    const POSSIBLE_ALL_COURTS_FIELDS = [
        'owner',
        'pesel',
        'parents',
        'page',
        'searchActual',
        'searchFull',
    ];

    let result = query;
    if (!query.courts) {
        result = Object.fromEntries(
            Object.entries(result).filter(([key]) =>
                POSSIBLE_ALL_COURTS_FIELDS.includes(key),
            ),
        );
    }
    if (query.searchFull) {
        result = Object.fromEntries(
            Object.entries(result).filter(([key]) =>
                INDEXED_FULL_FIELDS.includes(key),
            ),
        );
    }
    return result;
};

export const buildOptionalFilter = (field, statement, value) =>
    statement ? toFilterQuery(field, value) : {};

export const parseQueryToForm = (uri, userCourts) => {
    const [, queryString] = decodeURIComponent(uri).split('?');
    const query = Object.fromEntries(
        queryString
            .split('&')
            .map(item => item.split('='))
            .map(([key, value]) => [key, JSON.parse(value)]),
    );
    const result = {
        searchMode: ALL_COURTS,
        ignoreSeen: query.ignoreSeen,
        selectedList: query.listIds?.value || '',
        IIIContainsAnything: query.IIIsEmpty && !query.IIIsEmpty.value,
        IVContainsAnything: query.IVIsEmpty && !query.IVIsEmpty.value,
        chapterIOContentType: Object.fromEntries(
            ['land', 'building', 'apartment'].map(field => [
                field,
                query.chapterIOContentType
                    ? query.chapterIOContentType.value.includes(field)
                    : true,
            ]),
        ),
        noExtractedApartments:
            query.containsExtractedApartments &&
            !query.containsExtractedApartments.value,
    };
    if (query.courts) {
        result.selectedCourts = userCourts.filter(({name}) =>
            query.courts.values.includes(name),
        );
        result.searchMode = POSSIBLE_COURTS_ONLY;
    }
    result.matchQuery = Object.fromEntries(
        Object.entries(query)
            .filter(([, value]) => value.type === 'match')
            .map(([key, value]) => [key, value.value]),
    );
    result.rangeQuery = Object.fromEntries(
        Object.entries(query)
            .filter(([, value]) => value.type === 'range')
            .map(([key, value]) => [key, {from: value.from, to: value.to}]),
    );

    return result;
};
