import React, {memo} from 'react';
import {
    DateRangePicker,
    RangeField,
    Select,
    TextField,
} from '../../../component';

const PERPETUAL_USERS_ITEMS = [
    {
        value: true,
        label: 'Występuje',
    },
    {
        value: false,
        label: 'Nie występuje',
    },
];

const chapterII = memo(
    ({
        matchQuery,
        allCourtsSearching,
        onQueryMatchFieldChangeBuilder,
        containsPerpetualUsers,
        onSimpleEvent,
    }) => (
        <>
            <Select
                id={'containsPerpetualUsers'}
                title={'Użytkowanie wieczyste'}
                value={containsPerpetualUsers}
                onChange={onSimpleEvent}
                items={PERPETUAL_USERS_ITEMS}
            />
            <TextField
                id={'owner'}
                label={'Właściciel'}
                onChange={onQueryMatchFieldChangeBuilder}
                value={matchQuery.owner}
            />
            <TextField
                label={'Imiona rodziców'}
                id={'parents'}
                onChange={onQueryMatchFieldChangeBuilder}
                value={matchQuery.parents}
            />
            <TextField
                label={'PESEL'}
                id={'pesel'}
                onChange={onQueryMatchFieldChangeBuilder}
                value={matchQuery.pesel}
            />
            {!allCourtsSearching && (
                <DateRangePicker id={'birthdate'} label={'Data urodzenia'} />
            )}
            {!allCourtsSearching && (
                <TextField
                    label={'REGON'}
                    id={'regon'}
                    onChange={onQueryMatchFieldChangeBuilder}
                    value={matchQuery.regon}
                    disabled={allCourtsSearching}
                />
            )}
            {!allCourtsSearching && (
                <RangeField
                    id={'ownersCount'}
                    label={'Ilu właścicieli'}
                    disabled={allCourtsSearching}
                />
            )}
        </>
    ),
);

export default chapterII;
