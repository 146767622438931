import React, {memo} from 'react';
import styles from './checkbox.module.css';
import {FormControlLabel} from '@mui/material';
import MuiCheckbox from '@mui/material/Checkbox';

const Checkbox = memo(({label, disabled, checked, onChange}) => (
    <span className={styles.item}>
        <FormControlLabel
            control={
                <MuiCheckbox
                    disabled={disabled}
                    color={'success'}
                    checked={checked}
                    onChange={onChange}
                />
            }
            label={label}
            sx={{margin: 0}}
        />
    </span>
));

export default Checkbox;
