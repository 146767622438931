import {Box, Button, Typography} from '@mui/material';
import styles from './AddNewListForm.module.css';
import {Checkbox, Indicator, TextField} from '../index';
import {ChromePicker} from 'react-color';
import React, {forwardRef} from 'react';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const addNewListForm = (
    {
        children,
        onTitleChanged,
        onHiddenChanged,
        title,
        hidden,
        color,
        onCreateNewList,
        onColorChanged,
        disabled,
        loading,
        coping,
    },
    ref,
) => {
    return (
        <Box itemProp={{ref}} sx={modalStyle}>
            <Typography variant="h6" component="h2">
                Dodaj nową listę
            </Typography>
            <form
                onSubmit={event => {
                    event.preventDefault();
                    onCreateNewList(true);
                }}
                className={styles.addNewListContainer}
            >
                {coping && (
                    <h6 style={{color: 'var(--red)'}}>
                        Uwaga, skopiowane księgi potrzebują chwili na
                        aktualizację. Nowa lista może wyglądać na pustą na
                        początku.
                    </h6>
                )}
                <TextField
                    autoFocus
                    disabled={disabled || loading}
                    value={title}
                    onChange={onTitleChanged}
                    style={{width: 300}}
                    label={'Nazwa listy'}
                />
                <Checkbox
                    disabled={disabled || loading}
                    checked={hidden}
                    onChange={onHiddenChanged}
                    label="Ukryta"
                />
                <ChromePicker
                    color={color}
                    onChange={onColorChanged}
                    disableAlpha
                />
                {loading && (
                    <Indicator style={{marginTop: 5, marginBottom: 5}} />
                )}
                {children}
                <div style={{display: 'flex'}}>
                    <Button
                        disabled={disabled || loading}
                        style={{marginTop: 20}}
                        type={'submit'}
                        fullWidth
                    >
                        Dodaj
                    </Button>
                    <Button
                        style={{marginTop: 20}}
                        onClick={() => onCreateNewList(false)}
                        fullWidth
                        disabled={loading}
                    >
                        Anuluj
                    </Button>
                </div>
            </form>
        </Box>
    );
};

export default forwardRef(addNewListForm);
