import React, {forwardRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    COUNT,
    SEARCH,
    SEARCH_AND_ADD_TO_LIST,
} from '../../../../const/endpoints';
import {AddNewListForm} from '../../../../component';
import {getRandomColor} from '../../../../utils/generator';
import HTTPService from '../../../../service/HTTPService';
import {logNetworkError} from '../../../../utils/error';

const AddResultsToList = ({uri, onClose, minimalScore, innerRef}) => {
    const [title, setTitle] = useState('title');
    const [color, setColor] = useState(getRandomColor());
    const [hidden, setHidden] = useState(false);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        HTTPService.customGet(uri.replace(SEARCH, COUNT), {
            params: {minimalScore},
        })
            .then(({data}) => setTotal(data))
            .catch(logNetworkError);
    }, [minimalScore, uri]);

    const onColorChanged = event => {
        setColor(event.hex);
    };

    const onTitleChanged = event => {
        setTitle(event.target.value);
    };

    const onHiddenChanged = event => {
        setHidden(event.target.checked);
    };

    const onCreateNewList = isSubmitted => {
        setLoading(true);
        const newUri = uri.replace(SEARCH, SEARCH_AND_ADD_TO_LIST);
        if (isSubmitted) {
            HTTPService.customPost(
                newUri,
                {title, color, hidden},
                {
                    params: {minimalScore},
                },
            )
                .then(() => {
                    setLoading(false);
                    window.alert('Lista została stworzona');
                    onClose();
                })
                .catch(err => {
                    setLoading(false);
                    if (
                        err.response?.data?.message ===
                        'this list already exists'
                    ) {
                        window.alert('Taka lista już istnieje');
                    }
                    logNetworkError(err);
                });
        } else {
            onClose();
        }
    };

    return (
        <AddNewListForm
            ref={innerRef}
            loading={loading}
            onCreateNewList={onCreateNewList}
            onColorChanged={onColorChanged}
            color={color}
            onTitleChanged={onTitleChanged}
            title={title}
            onHiddenChanged={onHiddenChanged}
            hidden={hidden}
        >
            <p>
                {`${
                    total > 10000
                        ? 'Zbyt dużo wyników by wszystkie dodać do listy. '
                        : ''
                }Do nowej listy zostanie dodane ${
                    total > 10000 ? 'tylko pierwsze' : ''
                } ${Math.min(10000, total)} ksiąg`}
            </p>
        </AddNewListForm>
    );
};

AddResultsToList.propTypes = {
    onClose: PropTypes.any,
    uri: PropTypes.any,
    minimalScore: PropTypes.any,
};

export default forwardRef((props, ref) => (
    <AddResultsToList innerRef={ref} {...props} />
));
