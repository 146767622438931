import React, {memo, PureComponent} from 'react';
import styles from './Table.module.css';
import PropTypes from 'prop-types';
import MaterialTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Link} from 'react-router-dom';

class Table extends PureComponent {
    static Row = memo(
        ({children, style, cellStyle, hover, onClick, to, defaultFull}) => {
            return (
                <TableRow style={style} hover={hover} onClick={onClick}>
                    {children.map((child, index) => (
                        <TableCell
                            className={styles.cell}
                            style={cellStyle}
                            scope="row"
                            key={index}
                        >
                            {to ? (
                                <Link
                                    draggable="false"
                                    to={{pathname: to, defaultFull}}
                                >
                                    {child}
                                </Link>
                            ) : (
                                child
                            )}
                        </TableCell>
                    ))}
                </TableRow>
            );
        },
    );

    render() {
        const {headers, className, children, style, containerRef} = this.props;
        return (
            <TableContainer
                style={style}
                className={className}
                component={Paper}
                ref={containerRef}
            >
                <MaterialTable aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {headers.map((header, index) => (
                                <TableCell
                                    className={`${styles.cell} ${styles.head}`}
                                    component="th"
                                    key={index}
                                >
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>{children}</TableBody>
                </MaterialTable>
            </TableContainer>
        );
    }
}

Table.propTypes = {
    containerRef: PropTypes.any,
    style: PropTypes.any,
    headers: PropTypes.array,
    children: PropTypes.array,
    className: PropTypes.any,
};

export default Table;
