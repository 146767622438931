import React, {useEffect, useState} from 'react';
import styles from './Filters.module.css';
import {Indicator, Table} from '../../component';
import HTTPService from '../../service/HTTPService';
import {logNetworkError} from '../../utils/error';
import {EDIT_FORM, RESULTS} from '../../const/routes';
import {useNavigate} from 'react-router-dom';
import {Button} from '@mui/material';

const FiltersPage = () => {
    const [forms, setForms] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        HTTPService.getForms()
            .then(({data}) => setForms(data))
            .catch(logNetworkError)
            .then(() => setLoading(false));
    }, []);

    const onDelete = id => {
        HTTPService.deleteFormById(id)
            .then(HTTPService.getForms)
            .then(({data}) => this.setState({forms: data}))
            .catch(logNetworkError);
    };

    return loading ? (
        <Indicator />
    ) : (
        <div className={styles.container}>
            <div className={styles.table}>
                <Table headers={['Tytuł', '', '', '']}>
                    {forms.map(form => (
                        <Table.Row key={form.id}>
                            {form.title}
                            <Button
                                onClick={() =>
                                    navigate(
                                        RESULTS(
                                            encodeURIComponent(
                                                form.queryString,
                                            ),
                                        ),
                                    )
                                }
                            >
                                Zobacz wyniki
                            </Button>
                            <Button
                                onClick={() => navigate(EDIT_FORM(form.id))}
                            >
                                Edytuj
                            </Button>
                            <Button onClick={() => onDelete(form.id)}>
                                Usuń
                            </Button>
                        </Table.Row>
                    ))}
                </Table>
            </div>
        </div>
    );
};

export default FiltersPage;
