import React, {useCallback, useEffect, useMemo, useState} from 'react';
import HTTPService from '../../../../service/HTTPService';
import styles from './Register.module.css';
import './style.css';
import {logNetworkError} from '../../../../utils/error';
import {Indicator, RegisterTopToolkit} from '../../../../component';
import {useLocation, useParams} from 'react-router-dom';

const Register = () => {
    const location = useLocation();
    const [register, setRegister] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [fullRegister, setFullRegister] = useState(
        !!location.state?.defaultFull,
    );
    const [lists, setLists] = useState([]);
    const [activeChapter, setActiveChapter] = useState(null);
    const params = useParams();

    const parseError = useCallback(code => {
        switch (code) {
            case 401:
                setError('Nie masz uprawnień do wyświetlania tej księgi');
                break;
            case 404:
                setError('Nie znaleziono księgi');
                break;
            default:
                setError('Wystąpił błąd');
        }
    }, []);

    const fetchRegister = useCallback(
        (chapter, fetchFull) => {
            setActiveChapter(chapter);
            setFullRegister(fetchFull);
            const {registerId} = params;
            setLoading(true);
            HTTPService.getRegisterById(registerId, fetchFull, chapter)
                .then(({data}) => {
                    setRegister(data.registerHTML);
                    setLists(data.lists);
                })
                .catch(err => {
                    logNetworkError(err);
                    parseError(err.response.status);
                })
                .then(() => setLoading(false));
        },
        [params, parseError],
    );

    useEffect(
        () => fetchRegister(undefined, !!location.state?.defaultFull),
        [fetchRegister, location.state?.defaultFull],
    );

    const onClick = event => {
        let preventDefault = true;
        const value = event.target.attributes?.getNamedItem('value')?.value;
        switch (value) {
            case 'Dział I-O':
                fetchRegister('DIO', fullRegister);
                break;
            case 'Dział I-Sp':
                fetchRegister('DIS', fullRegister);
                break;
            case 'Dział II':
                fetchRegister('DII', fullRegister);
                break;
            case 'Dział III':
                fetchRegister('DIII', fullRegister);
                break;
            case 'Dział IV':
                fetchRegister('DIV', fullRegister);
                break;
            case 'Okładka':
                fetchRegister('OKLADKA', fullRegister);
                break;
            case 'Powrót':
                window.history.back();
                break;
            default:
                preventDefault = false;
        }
        preventDefault && event.preventDefault();
    };

    const switchRegisterVersion = () => {
        activeChapter !== 'OKLADKA'
            ? fetchRegister(activeChapter, !fullRegister)
            : fetchRegister('DIO', !fullRegister);
    };

    const isFavourite = useMemo(
        () => lists.some(({favourite}) => favourite),
        [lists],
    );
    const isHidden = useMemo(
        () => lists.some(({defaultHiddenList}) => defaultHiddenList),
        [lists],
    );

    return (
        <div className={styles.container}>
            {loading ? (
                <Indicator />
            ) : !!error ? (
                <div className={styles.noPermission}>{error}</div>
            ) : (
                <>
                    <RegisterTopToolkit
                        fullRegister={fullRegister}
                        isFavourite={isFavourite}
                        isHidden={isHidden}
                        switchRegisterVersion={switchRegisterVersion}
                        lists={lists}
                    />
                    <div
                        onClick={onClick}
                        dangerouslySetInnerHTML={{
                            __html: register,
                        }}
                    />
                </>
            )}
        </div>
    );
};

export default Register;
