import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button} from '@mui/material';
import {
    CourtPicker,
    FormChapterLayout,
    Checkbox,
    FormHeader,
    EntryBasis,
    FormikFormFetcher,
} from '../../component';
import styles from './Form.module.css';
import HTTPService from '../../service/HTTPService';
import {connect} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {EDIT_FORM_RESULTS, RESULTS} from '../../const/routes';
import {
    cleanForbiddenQueryFields,
    toFilterByCourtsQuery,
    toMatchQuery,
    toRangeQuery,
    toFilterByIOContentTypeQuery,
    buildTextQuery,
    autofillRangeFields,
    buildOptionalFilter,
    toEntryBasisQuery,
} from '../../utils/query';
import {
    ALL_COURTS,
    POSSIBLE_COURTS_ONLY,
} from '../../component/courtPicker/courtPicker';
import * as SessionStorage from '../../service/SessionStorage';
import ChapterIO from './chapters/ChapterIO';
import ChapterII from './chapters/ChapterII';
import ChapterIII from './chapters/ChapterIII';
import ChapterIV from './chapters/ChapterIV';
import {logNetworkError} from '../../utils/error';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import {Formik} from 'formik';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';

const rangeStateInit = {from: '', to: ''};
const dateRangeStateInit = {from: null, to: null};

const defaultRangeFieldsState = {
    houseNumber: {...rangeStateInit},
    apartmentNumber: {...rangeStateInit},
    size: {...rangeStateInit},
    buildingsCount: {...rangeStateInit},
    apartmentsCount: {...rangeStateInit},
    fieldCount: {...rangeStateInit},
    floors: {...rangeStateInit},
    ownersCount: {...rangeStateInit},
    mortgageValue: {...rangeStateInit},
    extractedApartmentsCount: {...rangeStateInit},
    mentionsDate: {...dateRangeStateInit},
    mortgagesCount: {...rangeStateInit},
    birthdate: {...dateRangeStateInit},
};

const rangeQueryFields = [
    'extractedApartmentsCount',
    'apartmentsCount',
    'buildingsCount',
    'fieldCount',
    'floors',
    'apartmentNumber',
    'houseNumber',
    'size',
    'ownersCount',
    'mortgageValue',
    'mortgagesCount',
];

const defaultState = {
    matchQuery: {
        id: '',
        pesel: '',
        regon: '',
        street: '',
        town: '',
        owner: '',
        fieldNumber: '',
        fieldId: '',
        landUsePurpose: '',
        parents: '',
        registrationPrecinct: '',
        fieldNumbers: '',
        fieldIds: '',
        apartmentUsePurpose: '',
        buildingUsePurpose: '',
        commune: '',
        county: '',
        province: '',
        wholeRegister: '',
        claimOwners: '',
        claimOwnerParents: '',
        claimOwnerPesel: '',
        claimOwnerRegon: '',
        claimEntryContent: '',
        claimEntryType: '',
        mortgageOwner: '',
        mortgageOwnerParents: '',
        mortgageOwnerPesel: '',
        mortgageOwnerRegon: '',
        mortgageCurrency: '',
        mortgageOwnerKRS: '',
        mortgageAdministrator: '',
        mortgageOwnerHeadquarters: '',
        mortgageOwnerRole: '',
        mortgageType: '',
        mortgageInformation: '',
        claim: '',
        mortgageLegalRelationship: '',
        mentionsDescription: '',
        mentionsAuthors: '',
    },
    searchMode: POSSIBLE_COURTS_ONLY,
    IIIContainsAnything: false,
    IVContainsAnything: false,
    chapterIOContentType: {land: true, building: true, apartment: true},
    noExtractedApartments: false,
    ignoreSeen: false,
    containsPerpetualUsers: null,
    selectedList: '',
    fetchedForm: null,
    searchActual: true,
    searchFull: false,
    entryBasisType: null,
    entryBasis: {
        title: '',
        signature: '',
        date: {...dateRangeStateInit},
        author: '',
        headquarters: '',
    },
};

const Form = ({userData}) => {
    const [state, setState] = useState({
        ...defaultState,
        selectedCourts: userData.courts,
        lists: [],
        ...SessionStorage.getFormState(),
        fetchedForm: null,
        searchMode: isEmpty(userData.courts)
            ? ALL_COURTS
            : POSSIBLE_COURTS_ONLY,
    });

    const location = useLocation();
    const navigate = useNavigate();

    const redirectToInitialSearch = useCallback(() => {
        const params = new URLSearchParams(location.search);
        const matchQuery = Object.fromEntries(params.entries());
        const parsedMatchQuery = toMatchQuery(buildTextQuery(matchQuery));
        const uri = HTTPService.getSearchUri(parsedMatchQuery);
        navigate(RESULTS(encodeURIComponent(uri)));
    }, [location.search, navigate]);

    useEffect(() => {
        if (location.search) {
            redirectToInitialSearch();
        }

        HTTPService.getUserLists()
            .then(({data}) =>
                setState(prevState => ({...prevState, lists: data})),
            )
            .catch(logNetworkError);
    }, [location.search, redirectToInitialSearch]);

    const onFormFetched = useCallback((fetchedForm, form) => {
        const parsedState = merge(
            {...cloneDeep(defaultState), selectedCourts: []},
            fetchedForm,
        );
        setState(prevState => ({
            ...prevState,
            ...parsedState,
            fetchedForm: form,
        }));
    }, []);

    const onSearchClickHandler = useCallback(
        values => {
            const {lists, ...formState} = merge(state, values);
            const {
                matchQuery,
                rangeQuery,
                searchMode,
                selectedCourts,
                IIIContainsAnything,
                chapterIOContentType,
                IVContainsAnything,
                noExtractedApartments,
                ignoreSeen,
                selectedList,
                fetchedForm,
                containsPerpetualUsers,
                searchActual,
                searchFull,
                entryBasis,
                entryBasisType,
            } = formState;
            const parsedMatchQuery = toMatchQuery(buildTextQuery(matchQuery));
            const parsedRangeQuery = toRangeQuery(
                autofillRangeFields(rangeQuery),
            );
            const parsedCourtsQuery = toFilterByCourtsQuery(
                searchMode,
                selectedCourts,
            );
            const contentType =
                toFilterByIOContentTypeQuery(chapterIOContentType);

            const query = cleanForbiddenQueryFields({
                searchActual,
                searchFull,
                ignoreSeen,
                ...toEntryBasisQuery(
                    {...entryBasis},
                    entryBasisType,
                    userData.superuser,
                ),
                ...parsedMatchQuery,
                ...parsedRangeQuery,
                ...parsedCourtsQuery,
                ...buildOptionalFilter(
                    'containsPerpetualUsers',
                    containsPerpetualUsers !== null,
                    containsPerpetualUsers,
                ),
                ...buildOptionalFilter('listIds', selectedList, selectedList),
                ...buildOptionalFilter(
                    'IIIsEmpty',
                    IIIContainsAnything,
                    !IIIContainsAnything,
                ),
                ...buildOptionalFilter(
                    'IVIsEmpty',
                    IVContainsAnything,
                    !IVContainsAnything,
                ),
                ...buildOptionalFilter(
                    'chapterIOContentType',
                    contentType,
                    contentType,
                ),
                ...buildOptionalFilter(
                    'containsExtractedApartments',
                    noExtractedApartments,
                    !noExtractedApartments,
                ),
            });

            const uri = HTTPService.getSearchUri(query);

            SessionStorage.setFormState(formState);
            navigate(
                fetchedForm
                    ? EDIT_FORM_RESULTS(fetchedForm.id, encodeURIComponent(uri))
                    : RESULTS(encodeURIComponent(uri)),
            );
        },
        [navigate, state, userData.superuser],
    );

    const onQueryMatchFieldChangeBuilder = useCallback(event => {
        setState(prevState => ({
            ...prevState,
            matchQuery: {
                ...prevState.matchQuery,
                [event.target.id]: event.target.value,
            },
        }));
    }, []);

    const onSearchModeChange = useCallback(searchMode => {
        setState(prevState => ({...prevState, searchMode}));
    }, []);

    const onIgnoreSeenChange = useCallback(event => {
        setState(prevState => ({
            ...prevState,
            ignoreSeen: event.target.checked,
        }));
    }, []);

    const onSearchFullChange = useCallback(event => {
        setState(prevState => ({
            ...prevState,
            searchFull: event.target.checked,
        }));
    }, []);

    const onSearchActualChange = useCallback(event => {
        setState(prevState => ({
            ...prevState,
            searchActual: event.target.checked,
        }));
    }, []);

    const onSelectedCourtsChange = useCallback(selectedCourts => {
        setState(prevState => ({...prevState, selectedCourts}));
    }, []);

    const cleanForm = useCallback(() => {
        setState(prevState => ({...prevState, ...defaultState}));
    }, []);

    const onExtractedApartmentsStateChange = useCallback(() => {
        setState(prevState => ({
            ...prevState,
            noExtractedApartments: !prevState.noExtractedApartments,
        }));
    }, []);

    const chapterIOTypeChanged = useCallback(
        value =>
            setState(prevState => ({
                ...prevState,
                chapterIOContentType: value,
            })),
        [],
    );

    const chapterIIIContainsAnythingClick = useCallback(
        () =>
            setState(prevState => ({
                ...prevState,
                IIIContainsAnything: !prevState.IIIContainsAnything,
            })),
        [],
    );

    const onSimpleEvent = useCallback(event => {
        setState(prevState => ({
            ...prevState,
            [event.target.id]: event.target.value,
        }));
    }, []);

    const onEntryBasisFieldChanged = useCallback(event => {
        setState(prevState => ({
            ...prevState,
            entryBasis: {
                ...prevState.entryBasis,
                [event.target.id]: event.target.value,
            },
        }));
    }, []);

    const validate = useCallback(values => {
        const errorsList = rangeQueryFields
            .map(field => {
                const from = get(values, `rangeQuery.${field}.from`);
                const to = get(values, `rangeQuery.${field}.to`);
                const fromError = Number.isNaN(Number(from));
                const toError = Number.isNaN(Number(to));
                return fromError || toError
                    ? {
                          rangeQuery: {
                              [field]: {
                                  from: Number.isNaN(Number(from)),
                                  to: Number.isNaN(Number(to)),
                              },
                          },
                      }
                    : null;
            })
            .filter(Boolean);
        return merge(...errorsList);
    }, []);

    const initialFormikValues = useMemo(
        () =>
            cloneDeep({
                rangeQuery: defaultRangeFieldsState,
            }),
        [],
    );

    const {
        matchQuery,
        searchMode,
        selectedCourts,
        IVContainsAnything,
        IIIContainsAnything,
        chapterIOContentType,
        noExtractedApartments,
        ignoreSeen,
        lists,
        selectedList,
        fetchedForm,
        containsPerpetualUsers,
        searchFull,
        searchActual,
        entryBasisType,
        entryBasis,
    } = state;
    const allCourtsSearching = searchMode === ALL_COURTS;

    return (
        <div className={styles.container}>
            {fetchedForm && <p>Aktualny formularz: {fetchedForm.title}</p>}
            <Formik
                onSubmit={onSearchClickHandler}
                initialValues={initialFormikValues}
                validate={validate}
            >
                {({handleSubmit, resetForm, isValid}) => (
                    <FormikFormFetcher onFormFetched={onFormFetched}>
                        <div className={styles.buttonContainer}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    cleanForm();
                                    resetForm();
                                }}
                            >
                                Wyczyść
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                                disabled={!isValid}
                            >
                                Szukaj
                            </Button>
                        </div>
                        {!allCourtsSearching && (
                            <CourtPicker
                                possibleCourts={userData?.courts}
                                onCourtsChange={onSelectedCourtsChange}
                                onCourtsModeChange={onSearchModeChange}
                                initialSelectedCourts={selectedCourts}
                            />
                        )}
                        <form onSubmit={handleSubmit}>
                            <FormHeader
                                searchActual={searchActual}
                                searchFull={searchFull}
                                onSearchFullChange={onSearchFullChange}
                                onSearchActualChange={onSearchActualChange}
                                allCourtsSearching={allCourtsSearching}
                                matchQuery={matchQuery}
                                ignoreSeen={ignoreSeen}
                                lists={lists}
                                selectedList={selectedList}
                                onQueryMatchFieldChangeBuilder={
                                    onQueryMatchFieldChangeBuilder
                                }
                                onIgnoreSeenChange={onIgnoreSeenChange}
                                onSimpleEvent={onSimpleEvent}
                            />
                            {!allCourtsSearching && (
                                <FormChapterLayout
                                    label="Dział I-O"
                                    additionalHeaderComponent={
                                        searchFull && (
                                            <h2 style={{marginLeft: 10}}>
                                                Dane są brane z wersji aktualnej
                                            </h2>
                                        )
                                    }
                                >
                                    <ChapterIO
                                        chapterType={chapterIOContentType}
                                        onChapterTypeChange={
                                            chapterIOTypeChanged
                                        }
                                        matchQuery={matchQuery}
                                        onQueryMatchFieldChangeBuilder={
                                            onQueryMatchFieldChangeBuilder
                                        }
                                        noExtractedApartments={
                                            noExtractedApartments
                                        }
                                        onExtractedApartmentsStateChange={
                                            onExtractedApartmentsStateChange
                                        }
                                    />
                                </FormChapterLayout>
                            )}
                            <FormChapterLayout
                                label="Dział II"
                                additionalHeaderComponent={
                                    <Checkbox
                                        id={'searchMode'}
                                        label={'Wszystkie sądy '}
                                        checked={searchMode === ALL_COURTS}
                                        onChange={event =>
                                            setState(prevState => ({
                                                ...prevState,
                                                searchMode: event.target.checked
                                                    ? ALL_COURTS
                                                    : POSSIBLE_COURTS_ONLY,
                                            }))
                                        }
                                    />
                                }
                            >
                                <ChapterII
                                    allCourtsSearching={allCourtsSearching}
                                    matchQuery={matchQuery}
                                    containsPerpetualUsers={
                                        containsPerpetualUsers
                                    }
                                    onSimpleEvent={onSimpleEvent}
                                    onQueryMatchFieldChangeBuilder={
                                        onQueryMatchFieldChangeBuilder
                                    }
                                />
                            </FormChapterLayout>
                            {!allCourtsSearching && (
                                <FormChapterLayout label="Dział II - podstawy wpisów">
                                    <EntryBasis
                                        entryBasisType={entryBasisType}
                                        setEntryBasisType={onSimpleEvent}
                                        entryBasis={entryBasis}
                                        onEntryBasisFieldChanged={
                                            onEntryBasisFieldChanged
                                        }
                                    />
                                </FormChapterLayout>
                            )}
                            {!allCourtsSearching && !searchFull && (
                                <FormChapterLayout label="Dział III">
                                    <ChapterIII
                                        onContainsAnythingChange={
                                            chapterIIIContainsAnythingClick
                                        }
                                        containsAnything={IIIContainsAnything}
                                        matchQuery={matchQuery}
                                        onQueryMatchFieldChangeBuilder={
                                            onQueryMatchFieldChangeBuilder
                                        }
                                    />
                                </FormChapterLayout>
                            )}
                            {!allCourtsSearching && (
                                <FormChapterLayout label="Dział IV">
                                    <ChapterIV
                                        onContainsAnythingChange={() =>
                                            setState(prevState => ({
                                                ...prevState,
                                                IVContainsAnything:
                                                    !IVContainsAnything,
                                            }))
                                        }
                                        containsAnything={IVContainsAnything}
                                        matchQuery={matchQuery}
                                        onQueryMatchFieldChangeBuilder={
                                            onQueryMatchFieldChangeBuilder
                                        }
                                    />
                                </FormChapterLayout>
                            )}
                            <div
                                className={styles.buttonContainer}
                                style={{marginTop: 8}}
                            >
                                <Button variant="contained" onClick={cleanForm}>
                                    Wyczyść
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={!isValid}
                                >
                                    Szukaj
                                </Button>
                            </div>
                        </form>
                    </FormikFormFetcher>
                )}
            </Formik>
        </div>
    );
};

const mapStateToProps = state => ({
    userData: state.auth.userData,
});

export default connect(mapStateToProps)(Form);
