import {Component} from 'react';
import {connect} from 'react-redux';
import {getRefreshToken, setRefreshToken} from './LocalStorage';
import HTTPService from './HTTPService';
import {logNetworkError} from '../utils/error';
import {setUserData} from '../store/action';

class AuthService extends Component {
    state = {
        loaded: false,
    };

    componentDidMount() {
        const token = getRefreshToken();
        HTTPService.setOnErrorCallback(this.onAuthError);
        if (token) {
            HTTPService.getRefreshedTokens(token)
                .then(({data}) => {
                    const {refreshToken, accessToken} = data;
                    setRefreshToken(refreshToken);
                    HTTPService.setAccessToken(accessToken);
                    return HTTPService.getUserData();
                })
                .then(({data}) => this.props.setUserData(data))
                .catch(logNetworkError)
                .then(() => this.setState({loaded: true}));
        } else {
            this.setState({loaded: true});
        }
    }

    onAuthError = err => {
        logNetworkError(err);
        this.props.setUserData(null);
    };

    render() {
        const {children} = this.props;
        return this.state.loaded ? children : null;
    }
}

const mapDispatchToProps = {
    setUserData,
};

export default connect(null, mapDispatchToProps)(AuthService);
