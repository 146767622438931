import {Modal} from '@mui/material';
import React, {Component} from 'react';
import {AddNewListForm} from '../../../component';
import {getRandomColor} from '../../../utils/generator';
import PropTypes from 'prop-types';
import HTTPService from '../../../service/HTTPService';
import {logNetworkError} from '../../../utils/error';

class AddNewList extends Component {
    state = {
        title: '',
        color: getRandomColor(),
        hidden: false,
    };

    onColorChanged = event => {
        this.setState({color: event.hex});
    };

    onTitleChanged = event => {
        this.setState({title: event.target.value});
    };

    onHiddenChanged = event => {
        this.setState({hidden: event.target.checked});
    };

    onCreateNewList = isSubmitted => {
        const {title, color, hidden} = this.state;
        if (isSubmitted) {
            HTTPService.createNewList(
                title,
                color,
                hidden,
                this.props.listIdToCopy,
            )
                .then(({data}) => this.props.onCreateNewList(data))
                .catch(err => {
                    if (
                        err.response?.data?.message ===
                        'this list already exists'
                    ) {
                        window.alert('Taka lista już istnieje');
                    }
                    logNetworkError(err);
                });
            this.setState({color: getRandomColor(), title: ''});
        } else {
            this.props.onCreateNewList(null);
        }
    };

    render() {
        const {title, color, hidden} = this.state;
        const {open, listIdToCopy} = this.props;
        return (
            <Modal open={open}>
                <AddNewListForm
                    onCreateNewList={this.onCreateNewList}
                    onColorChanged={this.onColorChanged}
                    color={color}
                    onTitleChanged={this.onTitleChanged}
                    title={title}
                    onHiddenChanged={this.onHiddenChanged}
                    hidden={hidden}
                    coping={!!listIdToCopy}
                />
            </Modal>
        );
    }
}

AddNewList.propTypes = {
    onCreateNewList: PropTypes.any,
    open: PropTypes.any,
    listIdToCopy: PropTypes.any,
};

export default AddNewList;
