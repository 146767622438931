import React, {Component} from 'react';
import styles from './Changes.module.css';
import {Indicator, TextField} from '../../component';
import HTTPService from '../../service/HTTPService';
import {logNetworkError} from '../../utils/error';
import {connect} from 'react-redux';
import {Button} from '@mui/material';
import {setUserData} from '../../store/action';

class ChangesPage extends Component {
    state = {changes: '', loading: true, date: null};

    componentDidMount() {
        this.fetchChanges()
            .then(() => this.setState({loading: false}))
            .then(HTTPService.getUserData)
            .then(({data}) => this.props.setUserData(data))
            .catch(logNetworkError);
    }

    onUploadChanges = () => {
        this.setState({loading: true});
        HTTPService.updateLatestChanges(this.state.changes)
            .then(this.fetchChanges)
            .then(() => window.alert('Zmiany zostały opublikowane'))
            .catch(logNetworkError)
            .then(() => this.setState({loading: false}));
    };

    fetchChanges = () =>
        HTTPService.getLatestChanges()
            .then(({data}) => this.setState(data))
            .catch(logNetworkError);

    onChangesChanged = event => {
        this.setState({changes: event.target.value});
    };

    render() {
        const {loading, changes, date} = this.state;
        const {userData} = this.props;
        return loading ? (
            <Indicator />
        ) : (
            <div className={styles.container}>
                <div>
                    <div>{new Date(date)?.toLocaleString()}</div>
                    {userData.superuser ? (
                        <div style={{width: 700}}>
                            <TextField
                                label={'Ostatnie zmiany'}
                                value={changes}
                                onChange={this.onChangesChanged}
                                multiline
                            />
                            <Button onClick={this.onUploadChanges}>
                                Update
                            </Button>
                        </div>
                    ) : (
                        <pre
                            style={{
                                width: 700,
                                overflowWrap: 'break-word',
                                whiteSpace: 'break-spaces',
                            }}
                        >
                            {changes}
                        </pre>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userData: state.auth.userData,
});

const mapDispatchToProps = {
    setUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangesPage);
