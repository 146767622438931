export const createReducer = (initialState = {}, actionHandlers = {}) => (
    state,
    action,
) => {
    if (!state) {
        return initialState;
    }

    const handler = actionHandlers[action.type];

    return handler ? handler(state, action) : state;
};
