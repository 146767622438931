import {Box, Button, Typography} from '@mui/material';
import styles from './SaveFiltersForm.module.css';
import {TextField} from '../index';
import React, {forwardRef, useCallback, useState} from 'react';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const SaveFiltersForm = ({initialTitle, loading, onSubmit, onCancel}, ref) => {
    const [title, setTitle] = useState(initialTitle);
    const onSubmitCallback = useCallback(
        event => {
            event.preventDefault();
            onSubmit(title);
        },
        [onSubmit, title],
    );
    return (
        <Box itemProp={{ref}} sx={modalStyle}>
            <Typography variant="h6" component="h2">
                Zapisz formularz
            </Typography>
            <form
                onSubmit={onSubmitCallback}
                className={styles.addNewListContainer}
            >
                <TextField
                    autoFocus
                    disabled={loading}
                    value={title}
                    onChange={event => setTitle(event.target.value)}
                    style={{width: 300}}
                    label={'Nazwa listy'}
                />
                <div style={{display: 'flex', marginTop: 20}}>
                    <Button disabled={loading} type={'submit'} fullWidth>
                        Zapisz
                    </Button>
                    <Button onClick={onCancel} fullWidth disabled={loading}>
                        Anuluj
                    </Button>
                </div>
            </form>
        </Box>
    );
};

export default forwardRef(SaveFiltersForm);
