import styles from './EditList.module.css';
import {Box, Button, Typography} from '@mui/material';
import React, {Component} from 'react';
import {Checkbox, TextField} from '../../../component';
import {ChromePicker} from 'react-color';
import {getRandomColor} from '../../../utils/generator';
import PropTypes from 'prop-types';
import HTTPService from '../../../service/HTTPService';
import {logNetworkError} from '../../../utils/error';
import {isSpecialList} from '../../../utils/list';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

class EditList extends Component {
    state = {
        title: '',
        color: '',
        hidden: false,
    };

    constructor(props) {
        super(props);
        this.state.color = props.selectedList.color;
        this.state.title = props.selectedList.title;
        this.state.hidden = props.selectedList.hidden;
    }

    onColorChanged = event => {
        this.setState({color: event.hex});
    };

    onTitleChanged = event => {
        this.setState({title: event.target.value});
    };

    onHiddenChanged = event => {
        this.setState({hidden: event.target.checked});
    };

    onListEdit = event => {
        const {title, color, hidden} = this.state;
        event.preventDefault();
        HTTPService.editList(this.props.selectedlist.id, title, color, hidden)
            .then(() => this.props.onEditList(true))
            .catch(err => {
                if (
                    err.response?.data?.message === 'this list already exists'
                ) {
                    window.alert('Taka lista już istnieje');
                }
                logNetworkError(err);
            });
        this.setState({color: getRandomColor(), title: ''});
    };

    render() {
        const {title, color, hidden} = this.state;
        const {selectedList} = this.props;
        return (
            <Box sx={modalStyle}>
                <Typography variant="h6" component="h2">
                    Edytuj listę
                </Typography>
                <form
                    onSubmit={this.onListEdit}
                    className={styles.addNewListContainer}
                >
                    <TextField
                        disabled={isSpecialList(selectedList)}
                        value={title}
                        onChange={this.onTitleChanged}
                        style={{width: 300}}
                        label={'Nazwa listy'}
                    />
                    <Checkbox
                        checked={hidden}
                        onChange={this.onHiddenChanged}
                        disabled={isSpecialList(selectedList)}
                        label="Ukryta"
                    />
                    <ChromePicker
                        color={color}
                        onChange={this.onColorChanged}
                        disableAlpha
                    />
                    <div style={{display: 'flex'}}>
                        <Button
                            style={{marginTop: 20}}
                            type={'submit'}
                            fullWidth
                        >
                            Edytuj
                        </Button>
                        <Button
                            style={{marginTop: 20}}
                            onClick={() => this.props.onEditList(false)}
                            fullWidth
                        >
                            Anuluj
                        </Button>
                    </div>
                </form>
            </Box>
        );
    }
}

EditList.propTypes = {
    onEditList: PropTypes.any,
    selectedList: PropTypes.any,
};

export default EditList;
