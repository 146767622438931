import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styles from './Results.module.css';
import HTTPService from '../../../service/HTTPService';
import {logNetworkError} from '../../../utils/error';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    Indicator,
    SaveFiltersForm,
    SearchResultsList,
    TextField,
} from '../../../component';
import {
    getResultsPosition,
    setResultsPosition,
} from '../../../service/SessionStorage';
import {Button, Modal} from '@mui/material';
import AddResultsToList from './AddResultsToList/AddResultsToList';
import {useParams} from 'react-router-dom';

const Results = () => {
    const [registers, setRegisters] = useState([]);
    const [next, setNext] = useState('');
    const [loading, setLoading] = useState(true);
    const [addResultsToListModalOpened, setAddResultsToListModalOpened] =
        useState(false);
    const [saveFormModalOpened, setSaveFormModalOpened] = useState(false);
    const [savingForm, setSavingForm] = useState(false);
    const [fetchedForm, setFetchedForm] = useState(null);
    const [editableForm, setEditableForm] = useState(null);
    const [minimalScore, setMinimalScore] = useState('0');
    const [minimalScoreError, setMinimalScoreError] = useState('');
    const [openDefaultFullRegister, setOpenDefaultFullRegister] =
        useState(false);
    const [listId, setListId] = useState(null);
    const params = useParams();
    const uri = useMemo(() => decodeURIComponent(params.uri), [params?.uri]);
    const containerRef = useRef();

    const setSearchedListId = uri => {
        const elements = Object.fromEntries(
            uri
                .split('?')[1]
                .split('&')
                .map(element => element.split('=')),
        );
        if (elements.listIds) {
            const listId = JSON.parse(elements.listIds).value;
            setListId(listId);
        }
    };

    const onEndReached = useCallback(
        callback => {
            next &&
                HTTPService.customGet(next)
                    .then(({data}) => {
                        setRegisters(prevState => [
                            ...prevState,
                            ...data.results,
                        ]);
                        setNext(data.next);
                        callback?.();
                    })
                    .catch(logNetworkError);
        },
        [next],
    );

    const onRegistersLoaded = useCallback(() => {
        const {offset, uri: lastUri} = getResultsPosition() || {};
        const {clientHeight} = containerRef.current || {};
        if (uri === lastUri && clientHeight) {
            const {innerHeight} = window;
            if (offset + innerHeight < clientHeight) {
                window.scrollTo(0, offset);
            } else {
                onEndReached(onRegistersLoaded);
            }
        }
    }, [onEndReached, uri]);

    useEffect(() => {
        const {id} = params;
        if (id) {
            HTTPService.getFormById(id)
                .then(({data}) => setFetchedForm(data))
                .catch(logNetworkError);
        }
        setSearchedListId(uri);
        HTTPService.customGet(uri)
            .then(({data}) => {
                setOpenDefaultFullRegister(uri.includes('searchFull=true'));
                setRegisters(data.results);
                setNext(data.next);
                setLoading(false);
                onRegistersLoaded();
            })
            .catch(err => {
                logNetworkError(err);
                window.alert('nie udało się odnaleźć');
                window.history.back();
            });
        // eslint-disable-next-line
    }, [params, uri]);

    const onSaveForm = useCallback(
        title => {
            setSavingForm(true);
            const promise = editableForm
                ? HTTPService.updateForm(editableForm.id, title, uri).then(
                      ({data}) => setFetchedForm(data),
                  )
                : HTTPService.saveForm(title, uri);
            promise
                .then(() => {
                    setSaveFormModalOpened(false);
                })
                .catch(logNetworkError)
                .then(() => {
                    setSavingForm(false);
                    setEditableForm(null);
                });
        },
        [editableForm, uri],
    );

    const onRowClick = useCallback(() => {
        const {uri} = params;
        setResultsPosition({
            uri,
            offset: window.pageYOffset,
        });
    }, [params]);

    const hideRegister = useCallback((registerId, event) => {
        event.preventDefault();
        event.stopPropagation();
        HTTPService.addRegistryToHidden(registerId).catch(logNetworkError);
        setRegisters(prevState =>
            prevState.filter(({id}) => id !== registerId),
        );
    }, []);

    const removeRegisterFromList = useCallback(
        (registerId, event) => {
            event.preventDefault();
            event.stopPropagation();
            HTTPService.removeRegisterFromList(registerId, listId).catch(
                logNetworkError,
            );
            setRegisters(prevState =>
                prevState.filter(({id}) => id !== registerId),
            );
        },
        [listId],
    );

    const onMinimalScoreChange = useCallback(event => {
        const score = event.target.value.replace(',', '.').trim();
        setMinimalScore(event.target.value);
        if (isNaN(score)) {
            setMinimalScoreError('musi być liczbą');
        } else if (Number(score) < 1) {
            setMinimalScoreError('nie może być mniejsza od 1');
        } else {
            setMinimalScoreError('');
        }
    }, []);

    return loading ? (
        <Indicator />
    ) : registers.length ? (
        <InfiniteScroll
            pageStart={0}
            dataLength={registers.length}
            next={onEndReached}
            hasMore={!!next}
            className={styles.container}
            loader={<h4>Loading...</h4>}
        >
            <TextField
                label={'Minimalny score'}
                style={{width: 150}}
                value={minimalScore}
                onChange={onMinimalScoreChange}
                error={!!minimalScoreError}
                helperText={minimalScoreError}
            />
            <Button
                onClick={() => setAddResultsToListModalOpened(true)}
                style={{marginTop: 5, marginBottom: 5}}
                disabled={!!minimalScoreError}
            >
                Dodaj wszystkie wyniki do listy
            </Button>
            <Button onClick={() => setSaveFormModalOpened(true)}>
                Zapisz nowy formularz
            </Button>
            {fetchedForm && (
                <Button
                    onClick={() => {
                        setSaveFormModalOpened(true);
                        setEditableForm(fetchedForm);
                    }}
                >
                    Edytuj formularz "{fetchedForm.title}"
                </Button>
            )}
            <Modal open={saveFormModalOpened}>
                <SaveFiltersForm
                    initialTitle={editableForm?.title || ''}
                    loading={savingForm}
                    onSubmit={onSaveForm}
                    onCancel={() => {
                        setSaveFormModalOpened(false);
                        setEditableForm(null);
                    }}
                />
            </Modal>
            <Modal open={addResultsToListModalOpened}>
                <AddResultsToList
                    minimalScore={Number.parseFloat(
                        minimalScore.replace(',', '.').trim(),
                    )}
                    uri={uri}
                    onClose={() => setAddResultsToListModalOpened(false)}
                />
            </Modal>
            <SearchResultsList
                listId={listId}
                openDefaultFullRegister={openDefaultFullRegister}
                hideRegister={hideRegister}
                onRowClick={onRowClick}
                registers={registers}
                containerRef={containerRef}
                removeRegisterFromList={removeRegisterFromList}
            />
        </InfiniteScroll>
    ) : (
        <p>Nie ma żadnych wyników</p>
    );
};

export default Results;
