import React, {useCallback} from 'react';
import {Checkbox} from '../../index';

const LAND = 'land';
const BUILDING = 'building';
const APARTMENT = 'apartment';

const ChapterIOContentPicker = ({onChapterTypeChange, chapterType}) => {
    const onChange = useCallback(
        field => event => {
            const newChapterTypes = {
                ...chapterType,
                [field]: event.target.checked,
            };
            onChapterTypeChange(newChapterTypes);
        },
        [chapterType, onChapterTypeChange],
    );

    const onAllChange = useCallback(() => {
        const chapterTypesCopy = {...chapterType};
        const allChecked = Object.values(chapterType).every(Boolean);
        Object.keys(chapterType).forEach(
            key => (chapterTypesCopy[key] = !allChecked),
        );
        onChapterTypeChange(chapterTypesCopy);
    }, [chapterType, onChapterTypeChange]);

    return (
        <span style={{display: 'flex', flexDirection: 'row'}}>
            <Checkbox
                checked={Object.values(chapterType).every(Boolean)}
                label="Wszystkie"
                id={'all'}
                onChange={onAllChange}
            />
            <Checkbox
                checked={!!chapterType[LAND]}
                id={LAND}
                onChange={onChange(LAND)}
                label="Działka"
            />
            <Checkbox
                id={BUILDING}
                onChange={onChange(BUILDING)}
                checked={!!chapterType[BUILDING]}
                label="Budynek"
            />
            <Checkbox
                checked={!!chapterType[APARTMENT]}
                id={APARTMENT}
                onChange={onChange(APARTMENT)}
                label="Lokal"
            />
        </span>
    );
};

export default ChapterIOContentPicker;
