import React, {memo} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {isSpecialList} from '../../utils/list';
import Delete from '@mui/icons-material/Delete';
import styles from '../../page/List/List.module.css';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import {ListItem} from '../index';
import {useNavigate} from 'react-router-dom';
import HTTPService from '../../service/HTTPService';
import {toFilterQuery} from '../../utils/query';
import {RESULTS} from '../../const/routes';

const listAccordion = memo(
    ({
        list,
        onEditClick,
        onCopyClick,
        onListRemove,
        onToggleListHide,
        removeRegisterFromList,
    }) => {
        const navigate = useNavigate();
        return (
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />} id={list.id}>
                    <div
                        style={{
                            alignSelf: 'center',
                            fontWeight: 'bold',
                            color: list.color,
                        }}
                    >
                        {list.title}
                    </div>
                    <Button
                        onClick={event => {
                            event.stopPropagation();
                            onEditClick(list);
                        }}
                    >
                        Edytuj
                    </Button>
                    <Button
                        onClick={event => {
                            event.stopPropagation();
                            onCopyClick(list.id);
                        }}
                    >
                        Kopiuj
                    </Button>
                    <Button
                        onClick={event => {
                            event.stopPropagation();
                            const uri = HTTPService.getSearchUri(
                                toFilterQuery('listIds', list.id),
                            );
                            navigate(RESULTS(encodeURIComponent(uri)));
                        }}
                    >
                        Zobacz wszystkie
                    </Button>
                    {!isSpecialList(list) && (
                        <Delete
                            className={styles.quickActionButton}
                            onClick={onListRemove(list)}
                        />
                    )}
                    {!isSpecialList(list) &&
                        (list.hidden ? (
                            <VisibilityOff
                                className={styles.quickActionButton}
                                onClick={onToggleListHide(list)}
                            />
                        ) : (
                            <Visibility
                                className={styles.quickActionButton}
                                onClick={onToggleListHide(list)}
                            />
                        ))}
                </AccordionSummary>
                <AccordionDetails>
                    <ListItem
                        list={list}
                        onRegisterDelete={registerId =>
                            removeRegisterFromList(registerId, list.id)
                        }
                    />
                </AccordionDetails>
            </Accordion>
        );
    },
);

export default listAccordion;
