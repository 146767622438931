import React, {memo, useCallback} from 'react';
import {Select, MenuItem, InputLabel, FormControl} from '@mui/material';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';

const select = memo(({title, items, onChange, value, id}) => {
    const formattedOnChange = useCallback(
        event => onChange({target: {...event.target, id}}),
        [id, onChange],
    );
    return (
        <FormControl variant="standard" sx={{marginLeft: 1, minWidth: 200}}>
            <InputLabel>{title}</InputLabel>
            <Select
                onChange={formattedOnChange}
                value={isEmpty(items) ? '' : value}
            >
                <MenuItem value="">
                    <em>Niewybrane</em>
                </MenuItem>
                {items.map(list => (
                    <MenuItem key={list.value} value={list.value}>
                        {list.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
});

select.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        }),
    ),
};

export default select;
