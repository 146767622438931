export const FORM = '/';
export const EDIT_FORM = id => `/edit-form/${id}`;
export const EDIT_FORM_RESULTS = (id, uri) => `/edit-form/${id}/results/${uri}`;
export const LOGIN = '/login';
export const RESULTS = uri => `/results/${uri}`;
export const REGISTER = id => `/register/${id}`;
export const COURTS = `/courts`;
export const USERS = `/users`;
export const EDIT_USER = id => `/user/edit/${id}`;
export const NEW_USER = `/user/new`;
export const CHANGES = `/changes`;
export const LISTS = `/list`;
export const NOTES = `/note`;
export const FILTERS = `/filter`;
