import React, {useMemo} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import FormPage from './page/Form/Form';
import LoginPage from './page/Login/Login';
import ResultsPage from './page/Form/Results/Results';
import RegisterPage from './page/Form/Results/Register/Register';
import CourtsPage from './page/Courts/Courts';
import CreateNewUserPage from './page/Users/CreateNewUser/CreateNewUser';
import UserEditPage from './page/Users/UserEdit/UserEdit';
import ListPage from './page/List/List';
import UsersPage from './page/Users/Users';
import NotesPage from './page/Notes/Notes';
import FilterPage from './page/Filters/Filters';
import {
    CHANGES,
    COURTS,
    EDIT_USER,
    FORM,
    LISTS,
    LOGIN,
    NEW_USER,
    NOTES,
    REGISTER,
    RESULTS,
    USERS,
    FILTERS,
    EDIT_FORM,
    EDIT_FORM_RESULTS,
} from './const/routes';
import withLayout from './hoc/withLayout';
import withAuth from './hoc/withAuth';
import ChangesPage from './page/Changes/Changes';
import {ThemeProvider} from '@mui/material';
import {theme} from './const/theme';

function App() {
    const Results = useMemo(() => withAuth(withLayout(ResultsPage, true)), []);
    const Form = useMemo(() => withAuth(withLayout(FormPage, true)), []);
    const Register = useMemo(() => withAuth(withLayout(RegisterPage)), []);
    const Courts = useMemo(() => withAuth(withLayout(CourtsPage)), []);
    const Users = useMemo(() => withAuth(withLayout(UsersPage)), []);
    const CreateNewUser = useMemo(
        () => withAuth(withLayout(CreateNewUserPage)),
        [],
    );
    const Changes = useMemo(() => withAuth(withLayout(ChangesPage)), []);
    const User = useMemo(() => withAuth(withLayout(UserEditPage)), []);
    const List = useMemo(() => withAuth(withLayout(ListPage)), []);
    const Notes = useMemo(() => withAuth(withLayout(NotesPage)), []);
    const Filter = useMemo(() => withAuth(withLayout(FilterPage)), []);

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Routes>
                    <Route path={LOGIN} element={<LoginPage />} />
                    <Route path={FORM} element={<Form />} />
                    <Route path={EDIT_FORM(':id')} element={<Form />} />
                    <Route path={RESULTS(':uri')} element={<Results />} />
                    <Route
                        path={EDIT_FORM_RESULTS(':id', ':uri')}
                        element={<Results />}
                    />
                    <Route
                        path={REGISTER(':registerId')}
                        element={<Register />}
                    />
                    <Route path={COURTS} element={<Courts />} />
                    <Route path={USERS} element={<Users />} />
                    <Route path={NEW_USER} element={<CreateNewUser />} />
                    <Route path={CHANGES} element={<Changes />} />
                    <Route path={EDIT_USER(':userId')} element={<User />} />
                    <Route path={LISTS} element={<List />} />
                    <Route path={NOTES} element={<Notes />} />
                    <Route path={FILTERS} element={<Filter />} />
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
