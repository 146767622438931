import React, {memo, useState} from 'react';
import HTTPService from '../../service/HTTPService';
import {logNetworkError} from '../../utils/error';
import {Button} from '@mui/material';
import {Indicator} from '../index';
import Dropzone from 'react-dropzone';
import styles from './registerCSVUploader.module.css';

const RegisterCSVUploader = memo(() => {
    const [file, setFile] = useState();
    const [loading, setLoading] = useState(false);
    const upload = () => {
        setLoading(true);
        HTTPService.uploadRegisterListToLoad(file)
            .then(() => {
                window.alert('Udało się załadować plik');
                setLoading(false);
                setFile(null);
            })
            .catch(e => {
                setLoading(false);
                window.alert('Nie udało się wysłać pliku');
                logNetworkError(e);
            });
    };

    return (
        <div className={styles.container}>
            <div>Załaduj plik CSV z listą ksiąg do pobrania</div>
            <Dropzone
                maxFiles={1}
                disabled={loading}
                onDrop={([newFile]) => setFile(newFile)}
            >
                {({getRootProps, getInputProps}) => (
                    <div className={styles.fileDropzone} {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div>{file ? file.name : 'Brak pliku'}</div>
                    </div>
                )}
            </Dropzone>
            <Button
                variant="contained"
                disabled={!file || loading}
                onClick={upload}
            >
                Wyślij
            </Button>
            {loading && <Indicator />}
        </div>
    );
});

export default RegisterCSVUploader;
