import React from 'react';
import styles from './formChapterLayout.module.css';

const formChapterLayout = ({children, label, additionalHeaderComponent}) => (
    <>
        <hr className={styles.line} />
        <div className={styles.titleContainer}>
            <h1 className={styles.title}>{label}</h1>
            {additionalHeaderComponent}
        </div>
        <div className={styles.section}>{children}</div>
    </>
);

export default formChapterLayout;
