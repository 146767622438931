import React, {useCallback, useMemo} from 'react';
import {DatePicker} from '../index';
import styles from './dateRangePicker.module.css';
import {DateTime} from 'luxon';
import classNames from 'classnames';
import {useFormikContext} from 'formik';
import get from 'lodash/get';

const DateRangePicker = ({
    label,
    onChange,
    disabled,
    value,
    autofillTo,
    id,
    className,
}) => {
    const {from, to} = value || {};
    const formik = useFormikContext();
    const valueFrom = get(formik?.values, `rangeQuery.${id}.from`, from);
    const valueTo = get(formik?.values, `rangeQuery.${id}.to`, to);

    const onFromChange = useCallback(
        date => {
            onChange(
                {
                    from:
                        date?.startOf('day').toISO({includeOffset: false}) ||
                        null,
                    to,
                },
                id,
            );
        },
        [onChange, to, id],
    );
    const onToChange = useCallback(
        date => {
            onChange(
                {
                    from,
                    to:
                        date?.endOf('day').toISO({includeOffset: false}) ||
                        null,
                },
                id,
            );
        },
        [onChange, from, id],
    );
    const inputLabelProps = useMemo(
        () => ({
            shrink: !!valueTo || (autofillTo && !!valueFrom),
        }),
        [autofillTo, valueFrom, valueTo],
    );

    const onFormikFromChange = useCallback(
        v =>
            formik?.setFieldValue(
                `rangeQuery.${id}.from`,
                v?.endOf('day').toISO({includeOffset: false}) || null,
            ),
        [formik, id],
    );

    const onFormikToChange = useCallback(
        v =>
            formik?.setFieldValue(
                `rangeQuery.${id}.to`,
                v?.endOf('day').toISO({includeOffset: false}) || null,
            ),
        [formik, id],
    );

    return (
        <div className={classNames(styles.container, className)}>
            <p>{label}</p>
            <DatePicker
                name={`rangeQuery.${id}.from`}
                disabled={disabled}
                label={'od'}
                onChange={onChange ? onFromChange : onFormikFromChange}
                className={styles.textField}
                value={valueFrom && DateTime.fromISO(valueFrom)}
                maxDate={(valueTo && DateTime.fromISO(valueTo)) || undefined}
            />
            <span style={{margin: '0 10px'}}>-</span>
            <DatePicker
                name={`rangeQuery.${id}.to`}
                placeholder={
                    autofillTo &&
                    from &&
                    DateTime.fromISO(from)?.toFormat('yyyy/MM/dd')
                }
                InputLabelProps={inputLabelProps}
                className={styles.textField}
                disabled={disabled}
                label={'do'}
                onChange={onChange ? onToChange : onFormikToChange}
                value={valueTo && DateTime.fromISO(valueTo)}
                minDate={
                    (valueFrom && DateTime.fromISO(valueFrom)) || undefined
                }
            />
        </div>
    );
};

export default DateRangePicker;
