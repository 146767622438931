import React, {Component} from 'react';
import styles from './Courts.module.css';
import {connect} from 'react-redux';
import {Indicator, RegisterCSVUploader, Switch, Table} from '../../component';
import HTTPService from '../../service/HTTPService';
import {logNetworkError} from '../../utils/error';
import {setUserData} from '../../store/action';
import {Button} from '@mui/material';
import {courtComparator} from '../../utils/comparator';

const TABLE_COUNT = 3;

class Courts extends Component {
    state = {courts: [], loading: true};

    componentDidMount() {
        HTTPService.getAllCourts()
            .then(({data}) =>
                this.setState({
                    courts: data.courts,
                    loading: false,
                }),
            )
            .catch(logNetworkError);
    }

    onFilledSwitchChange = event => {
        HTTPService.updateCourt(event.target.id, {
            notFilled: !event.target.checked,
        })
            .then(HTTPService.getAllCourts)
            .then(({data}) =>
                this.setState({
                    courts: data.courts,
                }),
            )
            .then(HTTPService.getUserData)
            .then(({data}) => this.props.setUserData(data))
            .catch(logNetworkError);
    };

    onCourtReset = court => () => {
        if (window.confirm(`Na pewno chcesz usunąć sąd ${court.name}?`)) {
            HTTPService.resetCourt(court.id).catch(logNetworkError);
        }
    };

    onAllCourtsReset = () => {
        if (window.confirm(`Na pewno chcesz usunąć wszystkie sądy?`)) {
            HTTPService.resetCourt().catch(logNetworkError);
        }
    };

    getChunkedCourts = () => {
        const {courts} = this.state;
        const step = courts.length / TABLE_COUNT;
        return [...new Array(TABLE_COUNT)].map((_, index) =>
            courts
                .sort(courtComparator)
                .slice(step * index, step * (index + 1)),
        );
    };

    getTable = chunk => (
        <Table
            style={{margin: 5}}
            headers={[
                'Nazwa',
                'Pełna',
                'Miejscowość',
                'Ostatni nr księgi',
                'Resetuj sąd',
            ]}
        >
            {chunk.map(court => (
                <Table.Row key={court.id}>
                    {court.name}
                    <Switch
                        checked={!court.notFilled}
                        onChange={this.onFilledSwitchChange}
                        id={court.id}
                    />
                    <input
                        style={{width: 200}}
                        defaultValue={court.city}
                        id={court.id}
                        onBlur={this.onCityChanged}
                    />
                    {court.maxRegisterId}
                    <Button onClick={this.onCourtReset(court)}>Resetuj</Button>
                </Table.Row>
            ))}
        </Table>
    );

    onCityChanged = event => {
        HTTPService.updateCourt(event.target.id, {
            city: event.target.value,
        }).catch(logNetworkError);
    };

    render() {
        const {loading} = this.state;
        const chunks = this.getChunkedCourts();
        return loading ? (
            <Indicator />
        ) : (
            <div className={styles.container}>
                <div style={{width: '100%'}}>
                    <RegisterCSVUploader />
                    <Button
                        variant="contained"
                        style={{margin: 10}}
                        onClick={this.onAllCourtsReset}
                    >
                        Resetuj wszystkie sądy
                    </Button>
                    <div style={{display: 'flex'}}>
                        {this.getTable(chunks[0])}
                        {this.getTable(chunks[1])}
                        {this.getTable(chunks[2])}
                    </div>
                    <Button
                        variant="contained"
                        style={{margin: 10}}
                        onClick={this.onAllCourtsReset}
                    >
                        Resetuj wszystkie sądy
                    </Button>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    setUserData,
};

export default connect(null, mapDispatchToProps)(Courts);
