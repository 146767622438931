import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import authReducer from './reducer/auth';

const rootReducer = combineReducers({
    auth: authReducer,
});

const composeEnhancers =
    process.env.NODE_ENV === 'development'
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
        : compose;

const configureStore = () =>
    createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default configureStore;
