import {Layout} from '../component';
import React from 'react';

const withLayout = (Component, centered) => () =>
    (
        <Layout centered={centered}>
            <Component />
        </Layout>
    );

export default withLayout;
