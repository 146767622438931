import React, {memo} from 'react';
import styles from '../../../page/Form/Form.module.css';
import {Checkbox, Select, TextField} from '../../index';

const formHeader = memo(
    ({
        allCourtsSearching,
        matchQuery,
        selectedList,
        ignoreSeen,
        lists,
        onQueryMatchFieldChangeBuilder,
        onSimpleEvent,
        onIgnoreSeenChange,
        searchActual,
        searchFull,
        onSearchActualChange,
        onSearchFullChange,
    }) =>
        allCourtsSearching ? (
            <>
                <Checkbox
                    checked={searchActual}
                    onChange={onSearchActualChange}
                    label="Aktualna"
                />
                <Checkbox
                    checked={searchFull}
                    onChange={onSearchFullChange}
                    label="Zupełna"
                />
            </>
        ) : (
            <>
                <div className={styles.row}>
                    <TextField
                        className={styles.wholeRegister}
                        label={'Numer KW'}
                        onChange={onQueryMatchFieldChangeBuilder}
                        id={'id'}
                        value={matchQuery.id}
                        disabled={allCourtsSearching}
                    />
                    <Select
                        title={'Szukaj w liście'}
                        value={selectedList}
                        id={'selectedList'}
                        onChange={onSimpleEvent}
                        items={lists.map(({id, title}) => ({
                            value: id,
                            label: title,
                        }))}
                    />
                    <Checkbox
                        checked={ignoreSeen}
                        onChange={onIgnoreSeenChange}
                        label={'Ukryj wyświetlone'}
                    />
                    <Checkbox
                        checked={searchActual}
                        onChange={onSearchActualChange}
                        label="Aktualna"
                    />
                    <Checkbox
                        checked={searchFull}
                        onChange={onSearchFullChange}
                        label="Zupełna"
                    />
                </div>
                <TextField
                    label={'Cała treść KW'}
                    id={'wholeRegister'}
                    onChange={onQueryMatchFieldChangeBuilder}
                    value={matchQuery.wholeRegister}
                    disabled={allCourtsSearching}
                />
            </>
        ),
);

export default formHeader;
