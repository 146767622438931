import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {LOGIN} from '../const/routes';

const withAuth = Component => {
    const WithAuth = ({userData}) => {
        const navigate = useNavigate();
        useEffect(() => {
            if (!userData) {
                navigate(LOGIN);
            }
        }, [navigate, userData]);

        return <div>{userData && <Component />}</div>;
    };

    const mapStateToProps = state => ({
        userData: state.auth.userData,
    });

    return connect(mapStateToProps)(WithAuth);
};
export default withAuth;
