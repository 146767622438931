import React, {useCallback, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import styles from './layout.module.css';
import {connect} from 'react-redux';
import Button from '@mui/material/Button';
import {
    CHANGES,
    COURTS,
    FILTERS,
    FORM,
    LISTS,
    LOGIN,
    NOTES,
    USERS,
} from '../../const/routes';
import {clearRefreshToken} from '../../service/LocalStorage';
import HTTPService from '../../service/HTTPService';
import {setUserData} from '../../store/action';
import classnames from 'classnames';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import {DateTime} from 'luxon';

const Layout = ({children, userData, setUserData, centered}) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const logout = useCallback(() => {
        clearRefreshToken();
        HTTPService.setAccessToken(null);
        setUserData(null);
        navigate(LOGIN);
    }, [setUserData, navigate]);

    const loadCourts = useCallback(() => {
        setLoading(true);
        setTimeout(() => setLoading(false), 3000);
        HTTPService.loadCourts().catch(console.error);
    }, [setLoading]);

    return (
        <main className={styles.container}>
            <div className={styles.topBar}>
                <span className={styles.group}>
                    <Link to={FORM}>Szukaj</Link>
                    <div style={{position: 'relative'}}>
                        <Link to={CHANGES}>Najnowsze zmiany</Link>
                        {!userData.changesChecked && (
                            <NewReleasesIcon
                                style={{
                                    fill: '#F6533E',
                                    position: 'absolute',
                                    top: -10,
                                    right: -15,
                                }}
                            />
                        )}
                    </div>
                    <Link to={LISTS}>Listy</Link>
                    <Link to={NOTES}>Notatki</Link>
                    <Link to={FILTERS}>Formularze</Link>
                    {userData.superuser && <Link to={USERS}>Użytkownicy</Link>}
                    {userData.superuser && <Link to={COURTS}>Sądy</Link>}
                    {userData.superuser && (
                        <Button
                            variant="contained"
                            color={'secondary'}
                            onClick={loadCourts}
                            disabled={loading}
                            style={{marginLeft: 20}}
                        >
                            Załaduj sądy
                        </Button>
                    )}
                </span>
                <span className={styles.group}>
                    <div className={styles.userName}>
                        <h4>{userData?.username}</h4>
                        {userData?.subscriptionEndsDate && (
                            <p
                                className={classnames({
                                    [styles.subscriptionDateSoonExpire]:
                                        DateTime.fromISO(
                                            userData?.subscriptionEndsDate,
                                        )
                                            .minus({month: 1})
                                            .diffNow() < 1,
                                })}
                            >
                                Konto wygasa:{' '}
                                {DateTime.fromISO(
                                    userData?.subscriptionEndsDate,
                                ).toFormat('dd.LL.yyyy')}
                            </p>
                        )}
                    </div>
                    <Button
                        variant="contained"
                        color={'secondary'}
                        onClick={logout}
                        style={{marginRight: 20}}
                    >
                        Wyloguj się
                    </Button>
                </span>
            </div>
            <div
                className={classnames(styles.content, {
                    [styles.centered]: centered,
                })}
            >
                {children}
            </div>
        </main>
    );
};

const mapStateToProps = state => ({
    userData: state.auth.userData,
});

const mapDispatchToProps = {
    setUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
