import {Table} from '../index';
import first from 'lodash/first';
import {REGISTER} from '../../const/routes';
import {Tooltip} from '@mui/material';
import NoteIcon from '@mui/icons-material/Note';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {getAddressInfo} from '../../utils/register';
import Delete from '@mui/icons-material/Delete';
import styles from '../../page/Form/Results/Results.module.css';
import React, {memo} from 'react';

const headers = [
    '',
    'Numer księgi',
    'Właściciel',
    'Miejscowość',
    'Adres',
    'Trafność',
    '',
];

const SearchResultsListItem = memo(
    ({
        register,
        onRowClick,
        openDefaultFullRegister,
        hideRegister,
        listId,
        removeRegisterFromList,
    }) => {
        return (
            <Table.Row
                hover
                style={{
                    cursor: 'pointer',
                    ...(first(register.lists)
                        ? {
                              backgroundColor: first(register.lists).color,
                          }
                        : {}),
                }}
                onClick={onRowClick}
                to={REGISTER(register.id)}
                defaultFull={openDefaultFullRegister}
            >
                {register.note && (
                    <Tooltip title={register.note}>
                        <NoteIcon />
                    </Tooltip>
                )}
                <div
                    style={{
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <VisibilityOff
                        style={{marginRight: 3}}
                        onClick={event => hideRegister(register.id, event)}
                    />
                    {register.id}
                </div>
                {register.owners?.map(({owner}) => owner).join(', ')}
                {register.position?.town || ''}
                {getAddressInfo(register)}
                {register.score.toFixed(10)}
                {listId && (
                    <Delete
                        onClick={event => {
                            removeRegisterFromList(register.id, event);
                        }}
                        className={styles.deleteButton}
                    />
                )}
            </Table.Row>
        );
    },
);

const searchResultsList = memo(
    ({
        containerRef,
        registers,
        onRowClick,
        listId,
        hideRegister,
        openDefaultFullRegister,
        removeRegisterFromList,
    }) => {
        return (
            <Table headers={headers} containerRef={containerRef}>
                {registers.map(register => (
                    <SearchResultsListItem
                        key={register.id}
                        onRowClick={onRowClick}
                        register={register}
                        listId={listId}
                        hideRegister={hideRegister}
                        openDefaultFullRegister={openDefaultFullRegister}
                        removeRegisterFromList={removeRegisterFromList}
                    />
                ))}
            </Table>
        );
    },
);

export default searchResultsList;
