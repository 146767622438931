import React, {PureComponent} from 'react';
import styles from './courtPicker.module.css';
import {Checkbox} from '../index';
import PropTypes from 'prop-types';
import {courtComparator} from '../../utils/comparator';
import CourtPickerItem from './courtPickerItem';

export const ALL_COURTS = 'ALL_COURTS';
export const POSSIBLE_COURTS_ONLY = 'POSSIBLE_COURTS_ONLY';

const ALL_COURTS_CHECKBOX = 'ALL_COURTS_CHECKBOX';
const FILLED_COURTS_CHECKBOX = 'FILLED_COURTS_CHECKBOX';
const NOT_FILLED_COURTS_CHECKBOX = 'NOT_FILLED_COURTS_CHECKBOX';

class CourtPicker extends PureComponent {
    state = {
        filledCourtsValues: [],
        unfilledCourtsValues: [],
        showMoreUnfilled: false,
        showMoreFilled: false,
    };

    constructor(props) {
        super(props);
        const fullCourts = props.possibleCourts.filter(
            ({notFilled}) => !notFilled,
        );
        const notFilledCourts = props.possibleCourts.filter(
            ({notFilled}) => notFilled,
        );
        this.state.filledCourtsValues = fullCourts.map(court =>
            props.initialSelectedCourts.some(({id}) => id === court.id),
        );
        this.state.unfilledCourtsValues = notFilledCourts.map(court =>
            props.initialSelectedCourts.some(({id}) => id === court.id),
        );
    }

    onValueChanged = (field, index) => () => {
        const checkboxValues = [...this.state[field]];
        checkboxValues[index] = !checkboxValues[index];
        this.setState({[field]: checkboxValues}, this.onCourtsChange);
    };

    onSelectAll = () => {
        const {unfilledCourtsValues, filledCourtsValues} = this.state;
        const newValue = !Object.values([
            ...unfilledCourtsValues,
            ...filledCourtsValues,
        ]).every(Boolean);
        this.setState(
            {
                unfilledCourtsValues: unfilledCourtsValues.fill(newValue),
                filledCourtsValues: filledCourtsValues.fill(newValue),
            },
            this.onCourtsChange,
        );
    };

    onSelectFilled = () => {
        const {filledCourtsValues} = this.state;
        const newValue = !Object.values(filledCourtsValues).every(Boolean);
        this.setState(
            {
                filledCourtsValues: filledCourtsValues.fill(newValue),
            },
            this.onCourtsChange,
        );
    };

    onSelectNotFilled = () => {
        const {unfilledCourtsValues} = this.state;
        const newValue = !Object.values(unfilledCourtsValues).every(Boolean);
        this.setState(
            {
                unfilledCourtsValues: unfilledCourtsValues.fill(newValue),
            },
            this.onCourtsChange,
        );
    };

    onCourtsChange = () => {
        const {filledCourtsValues, unfilledCourtsValues} = this.state;
        const {possibleCourts, onCourtsChange} = this.props;
        const sortedCourts = possibleCourts.sort(courtComparator);

        const fullCourts = sortedCourts.filter(({notFilled}) => !notFilled);
        const notFilledCourts = sortedCourts.filter(({notFilled}) => notFilled);
        const selectedFilledCourts = Object.entries(filledCourtsValues)
            .filter(([_, value]) => value)
            .map(([index]) => fullCourts[index]);
        const selectedUnfilledCourts = Object.entries(unfilledCourtsValues)
            .filter(([_, value]) => value)
            .map(([index]) => notFilledCourts[index]);
        onCourtsChange?.call(this, [
            ...selectedUnfilledCourts,
            ...selectedFilledCourts,
        ]);
    };

    onShowMoreUnfilledClick = () => {
        this.setState({showMoreUnfilled: !this.state.showMoreUnfilled});
    };

    onShowMoreFilledClick = () => {
        this.setState({showMoreFilled: !this.state.showMoreFilled});
    };

    render() {
        const {
            filledCourtsValues,
            unfilledCourtsValues,
            showMoreUnfilled,
            showMoreFilled,
        } = this.state;
        const {possibleCourts, style} = this.props;
        const sortedCourts = possibleCourts.sort(courtComparator);
        const fullCourts = sortedCourts.filter(({notFilled}) => !notFilled);
        const notFilledCourts = sortedCourts.filter(({notFilled}) => notFilled);
        return (
            <div className={styles.container} style={style}>
                <div style={{width: '100%'}}>
                    <div
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <span>Sądy:</span>
                        <Checkbox
                            id={ALL_COURTS_CHECKBOX}
                            label={'Wszystkie'}
                            onChange={this.onSelectAll}
                            checked={Object.values([
                                ...filledCourtsValues,
                                ...unfilledCourtsValues,
                            ]).every(Boolean)}
                        />
                        <Checkbox
                            id={FILLED_COURTS_CHECKBOX}
                            label={'Pełne'}
                            onChange={this.onSelectFilled}
                            checked={Object.values(filledCourtsValues).every(
                                Boolean,
                            )}
                        />
                        <Checkbox
                            id={NOT_FILLED_COURTS_CHECKBOX}
                            label={'Niepełne'}
                            onChange={this.onSelectNotFilled}
                            checked={Object.values(unfilledCourtsValues).every(
                                Boolean,
                            )}
                        />
                    </div>
                    <CourtPickerItem
                        courts={fullCourts}
                        courtValues={filledCourtsValues}
                        showMore={showMoreFilled}
                        label={'Pełne'}
                        field={'filledCourtsValues'}
                        onValueChanged={this.onValueChanged}
                        onShowMoreClick={this.onShowMoreFilledClick}
                    />
                    <CourtPickerItem
                        courts={notFilledCourts}
                        courtValues={unfilledCourtsValues}
                        showMore={showMoreUnfilled}
                        label={'Niepełne'}
                        field={'unfilledCourtsValues'}
                        onValueChanged={this.onValueChanged}
                        onShowMoreClick={this.onShowMoreUnfilledClick}
                    />
                </div>
            </div>
        );
    }
}

CourtPicker.propTypes = {
    style: PropTypes.any,
    possibleCourts: PropTypes.any,
    onCourtsChange: PropTypes.any,
    onCourtsModeChange: PropTypes.any,
    initialSelectedCourts: PropTypes.any,
};

export default CourtPicker;
