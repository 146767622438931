import React from 'react';
import DotLoader from 'react-spinners/PropagateLoader';

const indicator = ({style}) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            ...style,
        }}
    >
        <DotLoader />
    </div>
);

export default indicator;
