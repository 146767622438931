import axios from 'axios';
import decodeJwt from 'jwt-decode';
import {
    CHANGES,
    COURT,
    FAVOURITE,
    FILTER,
    HIDDEN,
    LIST,
    LIST_BY_ID,
    LIST_REGISTER,
    LOAD_COURTS,
    LOGIN,
    ME,
    NOTE,
    REFRESH,
    REGISTER,
    SEARCH,
    TWO_FACTOR_AUTH,
    UPDATE_REGISTERS,
    USER,
    USERS,
} from '../const/endpoints';
import {getRefreshToken, setRefreshToken} from './LocalStorage';
import {DateTime} from 'luxon';
import queryString from 'query-string';

const baseURL = `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? ':' + window.location.port : ''
}`;

const TOKEN_FREE_ENDPOINTS = [LOGIN, REFRESH];

const paramsSerializer = params => {
    return queryString.stringify(
        Object.fromEntries(
            Object.entries(params).map(([key, value]) => [
                key,
                typeof value === 'object' ? JSON.stringify(value) : value,
            ]),
        ),
    );
};

class AxiosInstance {
    accessToken = null;
    onError = null;

    constructor() {
        this.axios = axios.create({
            baseURL,
            paramsSerializer: {
                serialize: paramsSerializer,
            },
        });
        this.axios.interceptors.request.use(async config => {
            if (!TOKEN_FREE_ENDPOINTS.includes(config.url)) {
                await this.updateTokenIfExpired(config);
            }
            return config;
        }, Promise.reject);
    }

    updateTokenIfExpired = async config => {
        if (!this.isTokenValid()) {
            try {
                await this.updateTokenResult(config);
            } catch (err) {
                this.onError?.call(this, err);
                await Promise.reject(err);
            }
        }
    };

    isTokenValid = () => {
        if (!this.accessToken) {
            return;
        }
        const {exp} = decodeJwt(this.accessToken);
        const expirationTime = DateTime.fromSeconds(exp)
            .minus({minutes: 5})
            .toJSDate();
        return expirationTime > new Date();
    };

    updateTokenResult = async config => {
        const refreshToken = getRefreshToken();
        const {data} = await this.axios.get(REFRESH, {
            headers: {Authorization: `Bearer ${refreshToken}`},
        });
        this.setAccessToken(data.accessToken);
        config.headers = {Authorization: `Bearer ${data.accessToken}`};
        setRefreshToken(data.refreshToken);
    };

    setAccessToken = accessToken => {
        this.accessToken = accessToken;
        this.axios.defaults.headers = accessToken
            ? {Authorization: `Bearer ${accessToken}`}
            : {};
    };

    setOnErrorCallback = callback => (this.onError = callback);

    get = (...params) => this.axios.get(...params);
    post = (...params) => this.axios.post(...params);
    patch = (...params) => this.axios.patch(...params);
    delete = (...params) => this.axios.delete(...params);
}

class HTTPService {
    constructor() {
        this.axios = new AxiosInstance();
    }

    setAccessToken = accessToken => {
        this.axios.setAccessToken(accessToken);
    };

    setOnErrorCallback = callback => this.axios.setOnErrorCallback(callback);

    getUserData = () => this.axios.get(ME);

    login = (username, password) =>
        this.axios.post(LOGIN, {username, password});

    getSearchUri = params =>
        axios.getUri({
            url: SEARCH,
            params: {...params, page: 1},
            paramsSerializer: {
                serialize: paramsSerializer,
            },
        });

    getRegisterById = (id, full, chapter) =>
        this.axios.get(REGISTER(id), {params: {chapter, full}});

    loadCourts = () => this.axios.post(LOAD_COURTS);

    customGet = (uri, options) => this.axios.get(uri, options);

    customPost = (uri, body, options) => this.axios.post(uri, body, options);

    getRefreshedTokens = refreshToken =>
        this.axios.get(REFRESH, {
            headers: {Authorization: `Bearer ${refreshToken}`},
        });

    validate2FACode = code => this.axios.post(TWO_FACTOR_AUTH, {code});

    getAllCourts = () => this.axios.get(COURT());

    updateCourt = (id, court) => this.axios.patch(COURT(id), court);

    getAllUsers = (subscriptionEndsAfter, subscriptionEndsBefore) =>
        this.axios.get(USERS, {
            params: {
                ...(subscriptionEndsAfter ? {subscriptionEndsAfter} : {}),
                ...(subscriptionEndsBefore ? {subscriptionEndsBefore} : {}),
            },
        });

    getUserById = id => this.axios.get(USER(id));

    updateUser = (id, user) => this.axios.patch(USER(id), user);

    createNewUser = user => this.axios.post(USERS, user);

    deleteUser = id => this.axios.delete(USER(id));

    resetCourt = id => this.axios.delete(COURT(id));

    getLatestChanges = () => this.axios.get(CHANGES);

    updateLatestChanges = message => this.axios.post(CHANGES, {message});

    addRegistryToFavourite = registerId =>
        this.axios.post(FAVOURITE, null, {params: {registerId}});

    addRegistryToHidden = registerId =>
        this.axios.post(HIDDEN, null, {params: {registerId}});

    removeRegistryFromHidden = registerId =>
        this.axios.delete(HIDDEN, {params: {registerId}});

    removeRegisterFromFavourite = registerId =>
        this.axios.delete(FAVOURITE, {params: {registerId}});

    getUserLists = withRegisters =>
        this.axios.get(LIST, {params: {withRegisters}});

    removeRegisterFromList = (registerId, listId) =>
        this.axios.delete(LIST_REGISTER, {params: {registerId, listId}});

    createNewList = (title, color, hidden, listIdToCopy) =>
        this.axios.post(LIST, {title, color, hidden, listIdToCopy});

    removeList = id => this.axios.delete(LIST_BY_ID(id));

    addRegisterToList = (id, registerId) =>
        this.axios.post(LIST_BY_ID(id), {registerId});

    editList = (id, title, color, hidden) =>
        this.axios.patch(LIST_BY_ID(id), {title, color, hidden});

    updateNote = (registerId, content) =>
        this.axios.post(NOTE, {registerId, content});

    getNoteByRegisterId = registerId =>
        this.axios.get(NOTE, {params: {registerId}});

    getNotes = () => this.axios.get(NOTE);

    saveForm = (title, queryString) =>
        this.axios.post(FILTER(), {title, queryString});

    getForms = () => this.axios.get(FILTER());

    getFormById = id => this.axios.get(FILTER(id));

    deleteFormById = id => this.axios.delete(FILTER(id));

    updateForm = (id, title, queryString) =>
        this.axios.patch(FILTER(id), {title, queryString});

    uploadRegisterListToLoad = file => {
        const formData = new FormData();
        formData.append('file', file, 'registers.csv');
        return this.axios.post(UPDATE_REGISTERS, formData);
    };
}

export default new HTTPService();
