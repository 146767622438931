import {Component} from 'react';
import HTTPService from '../../../service/HTTPService';
import merge from 'lodash/merge';
import {parseQueryToForm} from '../../../utils/query';
import {logNetworkError} from '../../../utils/error';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useFormikContext} from 'formik';
import * as SessionStorage from '../../../service/SessionStorage';
import cloneDeep from 'lodash/cloneDeep';

class FormikClassFormFetcher extends Component {
    componentDidMount() {
        const {params, formik, onFormFetched, userData} = this.props;

        const savedFormState = SessionStorage.getFormState();
        const possibleFields = Object.keys(formik.values);
        const filteredSavedFormState =
            savedFormState &&
            Object.fromEntries(
                Object.entries(savedFormState).filter(([key]) =>
                    possibleFields.includes(key),
                ),
            );
        if (savedFormState) {
            formik.setValues({
                ...formik.values,
                ...filteredSavedFormState,
            });
        }

        const {id} = params;
        if (id) {
            HTTPService.getFormById(id)
                .then(({data: form}) => {
                    const fetchedForm = parseQueryToForm(
                        form.queryString,
                        userData.courts,
                    );
                    const initialValues = cloneDeep(formik.initialValues);
                    const parsedState = merge(
                        {...initialValues, selectedCourts: []},
                        fetchedForm,
                    );
                    onFormFetched(fetchedForm, form);
                    formik.setValues({
                        ...formik.values,
                        ...parsedState,
                    });
                })
                .catch(logNetworkError);
        }
    }

    render() {
        return this.props.children;
    }
}

const FormikFormFetcher = ({children, onFormFetched}) => {
    const params = useParams();
    const userData = useSelector(state => state.auth.userData);
    const formik = useFormikContext();

    return (
        <FormikClassFormFetcher
            params={params}
            formik={formik}
            onFormFetched={onFormFetched}
            userData={userData}
        >
            {children}
        </FormikClassFormFetcher>
    );
};

export default FormikFormFetcher;
