const FORM_STATE = '@form_state';
const RESULTS_POSITION = '@results_position';

export const getFormState = () =>
    JSON.parse(sessionStorage.getItem(FORM_STATE));
export const setFormState = state =>
    sessionStorage.setItem(FORM_STATE, JSON.stringify(state));
export const setResultsPosition = state =>
    sessionStorage.setItem(RESULTS_POSITION, JSON.stringify(state));
export const getResultsPosition = () =>
    JSON.parse(sessionStorage.getItem(RESULTS_POSITION));
