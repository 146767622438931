import React, {useEffect, useState} from 'react';
import {Indicator, UserForm} from '../../../component';
import styles from './CreateNewUser.module.css';
import HTTPService from '../../../service/HTTPService';
import {logNetworkError} from '../../../utils/error';
import {useNavigate} from 'react-router-dom';
import {USERS} from '../../../const/routes';
import {courtComparator} from '../../../utils/comparator';

const CreateNewUser = () => {
    const [possibleCourts, setPossibleCourts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        HTTPService.getAllCourts()
            .then(({data: courtsData}) => {
                setPossibleCourts(courtsData.courts.sort(courtComparator));
                setLoading(false);
            })
            .catch(logNetworkError);
    }, []);

    const onSubmit = newUser => {
        setError('');
        setLoading(true);
        HTTPService.createNewUser({
            username: newUser.username,
            password: newUser.password,
            disabled: newUser.disabled,
            courts: newUser.courts,
            superuser: newUser.superuser,
            allCourts: newUser.allCourts,
            subscriptionEndsDate: newUser.subscriptionEndsDate,
        })
            .then(() => {
                navigate(USERS);
            })
            .catch(err => {
                logNetworkError(err);
                setError(err.response.data.message);
            })
            .then(() => setLoading(false));
    };

    return loading ? (
        <Indicator />
    ) : (
        <div className={styles.container}>
            <UserForm
                onSubmit={onSubmit}
                possibleCourts={possibleCourts}
                submitLabel={'Dodaj'}
                error={error}
            />
        </div>
    );
};

export default CreateNewUser;
