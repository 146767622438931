import React, {memo} from 'react';
import {
    ChapterIOContentPicker,
    Checkbox,
    RangeField,
    TextField,
} from '../../../component';

const chapterIO = memo(
    ({
        matchQuery,
        onQueryMatchFieldChangeBuilder,
        chapterType,
        onChapterTypeChange,
        noExtractedApartments,
        onExtractedApartmentsStateChange,
    }) => (
        <>
            <ChapterIOContentPicker
                chapterType={chapterType}
                onChapterTypeChange={onChapterTypeChange}
            />
            <TextField
                label={'Obręb ewidencyjny'}
                id={'registrationPrecinct'}
                onChange={onQueryMatchFieldChangeBuilder}
                value={matchQuery.registrationPrecinct}
            />
            <TextField
                label={'Numer działki'}
                id={'fieldNumbers'}
                onChange={onQueryMatchFieldChangeBuilder}
                value={matchQuery.fieldNumbers}
            />
            <TextField
                label={'Identyfikator działki'}
                id={'fieldIds'}
                onChange={onQueryMatchFieldChangeBuilder}
                value={matchQuery.fieldIds}
            />
            <TextField
                label={'Sposób korzystania z działki'}
                id={'landUsePurpose'}
                onChange={onQueryMatchFieldChangeBuilder}
                value={matchQuery.landUsePurpose}
            />
            <RangeField id={'fieldCount'} label={'Ile działek'} />
            <RangeField id={'size'} label={'Powierzchnia'} />
            <TextField
                label={'Województwo'}
                id={'province'}
                onChange={onQueryMatchFieldChangeBuilder}
                value={matchQuery.province}
            />
            <TextField
                label={'Powiat'}
                id={'county'}
                onChange={onQueryMatchFieldChangeBuilder}
                value={matchQuery.county}
            />
            <TextField
                label={'Gmina'}
                id={'commune'}
                onChange={onQueryMatchFieldChangeBuilder}
                value={matchQuery.commune}
            />
            <TextField
                label={'Miejscowość'}
                id={'town'}
                onChange={onQueryMatchFieldChangeBuilder}
                value={matchQuery.town}
            />
            <TextField
                label={'Ulica'}
                id={'street'}
                onChange={onQueryMatchFieldChangeBuilder}
                value={matchQuery.street}
            />
            <RangeField
                id="houseNumber"
                label={'Numer budynku'}
                autofillTo={true}
            />
            <RangeField
                id={'apartmentNumber'}
                label={'Numer lokalu'}
                autofillTo={true}
            />
            <TextField
                label={'Przeznaczenie lokalu'}
                id={'apartmentUsePurpose'}
                onChange={onQueryMatchFieldChangeBuilder}
                value={matchQuery.apartmentUsePurpose}
            />
            <TextField
                label={'Przeznaczenie budynku'}
                id="buildingUsePurpose"
                onChange={onQueryMatchFieldChangeBuilder}
                value={matchQuery.buildingUsePurpose}
            />
            <RangeField id={'floors'} label={'Kondygnacja'} />
            <RangeField id={'buildingsCount'} label={'Ile budynków'} />
            <RangeField id={'apartmentsCount'} label={'Ile lokali'} />
            <Checkbox
                checked={noExtractedApartments}
                onChange={onExtractedApartmentsStateChange}
                id={'NoExtractedApartments'}
                label={'Brak wyodrębnionych lokali'}
            />
            <RangeField
                id={'extractedApartmentsCount'}
                label={'Ile wyodrębnionych lokali'}
            />
        </>
    ),
);

export default chapterIO;
