import React, {memo} from 'react';
import TextField from '@mui/material/TextField';
import styles from './TextField.module.css';
import classNames from 'classnames';

const textField = memo(
    ({
        onChange,
        label,
        style,
        className,
        value,
        multiline,
        error,
        helperText,
        ...props
    }) => {
        return (
            <TextField
                value={value}
                className={classNames(styles.field, className)}
                onChange={onChange}
                variant={'standard'}
                label={label}
                style={style}
                multiline={multiline}
                error={error}
                helperText={helperText}
                {...props}
            />
        );
    },
);

export default textField;
