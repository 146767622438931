import React, {useCallback, useMemo} from 'react';
import {TextField} from '../index';
import styles from './rangeField.module.css';
import {useFormikContext} from 'formik';
import get from 'lodash/get';

const RangeField = ({
    label,
    onChange,
    disabled,
    value: {from, to} = {},
    autofillTo,
    id,
}) => {
    const formik = useFormikContext();
    const valueFrom = get(formik.values, `rangeQuery.${id}.from`, from);
    const valueTo = get(formik.values, `rangeQuery.${id}.to`, to);

    const onFromChange = useCallback(
        event => {
            const newFrom = event.target.value;
            onChange({from: newFrom, to}, id);
        },
        [onChange, to, id],
    );
    const onToChange = useCallback(
        event => {
            const newTo = event.target.value;
            onChange({from, to: newTo}, id);
        },
        [onChange, from, id],
    );
    const inputLabelProps = useMemo(
        () => ({
            shrink: !!valueTo || (autofillTo && !!valueFrom),
        }),
        [autofillTo, valueFrom, valueTo],
    );
    return (
        <div className={styles.container}>
            <p>{label}</p>
            <TextField
                name={`rangeQuery.${id}.from`}
                disabled={disabled}
                label={'od'}
                onChange={onChange ? onFromChange : formik.handleChange}
                className={styles.textField}
                value={valueFrom}
                error={get(formik.errors, `rangeQuery.${id}.from`, false)}
            />
            <span style={{margin: '0 10px'}}>-</span>
            <TextField
                name={`rangeQuery.${id}.to`}
                placeholder={valueFrom}
                InputLabelProps={inputLabelProps}
                className={styles.textField}
                disabled={disabled}
                label={'do'}
                onChange={onChange ? onToChange : formik.handleChange}
                value={valueTo}
                error={get(formik.errors, `rangeQuery.${id}.to`, false)}
            />
        </div>
    );
};

export default RangeField;
