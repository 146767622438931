import React, {useEffect, useState} from 'react';
import {Indicator, UserForm} from '../../../component';
import styles from './UserEdit.module.css';
import HTTPService from '../../../service/HTTPService';
import {useNavigate, useParams} from 'react-router-dom';
import {logNetworkError} from '../../../utils/error';
import {USERS} from '../../../const/routes';
import {courtComparator} from '../../../utils/comparator';

const UserEditPage = () => {
    const [initialUser, setInitialUser] = useState(null);
    const [possibleCourts, setPossibleCourts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        Promise.all([
            HTTPService.getUserById(params.userId),
            HTTPService.getAllCourts(),
        ])
            .then(([{data: initialUser}, {data: courtsData}]) => {
                setInitialUser(initialUser);
                setPossibleCourts(courtsData.courts.sort(courtComparator));
                setLoading(false);
            })
            .catch(logNetworkError);
    }, [params.userId]);

    const onSubmit = user => {
        setError('');
        setLoading(true);
        HTTPService.updateUser(user.id, {
            username: user.username,
            ...(!!user.password ? {password: user.password} : {}),
            courts: user.courts,
            superuser: user.superuser,
            allCourts: user.allCourts,
            disabled: user.disabled,
            subscriptionEndsDate: user.subscriptionEndsDate,
            ...(user.reset2FaVerification ? {twoFAValidated: false} : {}),
        })
            .then(() => {
                navigate(USERS);
            })
            .catch(err => {
                logNetworkError(err);
                setError(err.response.data.message);
            })
            .then(() => setLoading(false));
    };

    const onDelete = () => {
        if (window.confirm(`Na pewno chcesz usunąć użytkownika?`)) {
            HTTPService.deleteUser(initialUser.id)
                .then(() => {
                    navigate(USERS);
                })
                .catch(logNetworkError);
        }
    };

    return loading ? (
        <Indicator />
    ) : (
        <div className={styles.container}>
            <UserForm
                onDelete={onDelete}
                onSubmit={onSubmit}
                possibleCourts={possibleCourts}
                initialUser={initialUser}
                submitLabel={'Zapisz'}
                error={error}
            />
        </div>
    );
};

export default UserEditPage;
