import styles from './List.module.css';
import {Button, Modal} from '@mui/material';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import HTTPService from '../../service/HTTPService';
import {logNetworkError} from '../../utils/error';
import {Indicator, ListAccordion} from '../../component';
import AddNewList from './AddNewList/AddNewList';
import EditList from './EditList/EditList';

class List extends Component {
    state = {
        lists: [],
        loading: true,
        addNewList: false,
        selectedList: null,
        listIdToCopy: null,
    };

    componentDidMount() {
        HTTPService.getUserLists(true)
            .then(({data}) => this.setState({lists: data, loading: false}))
            .catch(logNetworkError);
    }

    fetchUserLists = () => {
        HTTPService.getUserLists()
            .then(({data}) => this.setState({lists: data, loading: false}))
            .catch(logNetworkError);
    };

    removeRegisterFromList = (registerId, listId) => {
        const lists = [...this.state.lists];
        const editedListIndex = lists.findIndex(list => list.id === listId);
        const list = {...this.state.lists[editedListIndex]};
        list.registers = list.registers.filter(
            register => register.id !== registerId,
        );
        lists[editedListIndex] = list;
        this.setState({lists});
        HTTPService.removeRegisterFromList(registerId, listId).catch(
            logNetworkError,
        );
    };

    onCreateNewList = newList => {
        this.setState({
            addNewList: false,
            listIdToCopy: null,
        });
        if (newList) {
            const lists = [...this.state.lists];
            lists.push(newList);
            this.setState({lists});
        }
    };

    onEditList = edited => {
        this.setState({
            selectedList: null,
        });
        if (edited) {
            this.fetchUserLists();
        }
    };

    onListRemove = list => event => {
        event.preventDefault();
        event.stopPropagation();
        const confirmed =
            !list.registers.length ||
            window.confirm(
                'Czy na pewno chcesz usunąć tą listę? Posiada ona zapisane księgi.',
            );
        if (confirmed) {
            this.setState({loading: true});
            HTTPService.removeList(list.id)
                .then(() => {
                    const newLists = this.state.lists.filter(
                        ({id}) => list.id !== id,
                    );
                    this.setState({lists: newLists, loading: false});
                })
                .catch(logNetworkError);
        }
    };

    onToggleListHide = list => event => {
        event.preventDefault();
        event.stopPropagation();
        HTTPService.editList(list.id, list.title, list.color, !list.hidden)
            .then(this.fetchUserLists)
            .catch(logNetworkError);
    };

    render() {
        const {lists, loading, addNewList, selectedList, listIdToCopy} =
            this.state;
        return loading ? (
            <Indicator />
        ) : (
            <div className={styles.container}>
                <Button
                    style={{marginBottom: 10}}
                    onClick={() => this.setState({addNewList: true})}
                >
                    Dodaj Nową listę
                </Button>
                <AddNewList
                    open={addNewList}
                    listIdToCopy={listIdToCopy}
                    onCreateNewList={this.onCreateNewList}
                />
                <Modal open={!!selectedList}>
                    <EditList
                        selectedList={selectedList}
                        onEditList={this.onEditList}
                    />
                </Modal>
                {lists.map(list => (
                    <ListAccordion
                        key={list.id}
                        list={list}
                        removeRegisterFromList={this.removeRegisterFromList}
                        onListRemove={this.onListRemove}
                        onToggleListHide={this.onToggleListHide}
                        onCopyClick={listId =>
                            this.setState({
                                addNewList: true,
                                listIdToCopy: listId,
                            })
                        }
                        onEditClick={list =>
                            this.setState({selectedList: list})
                        }
                    />
                ))}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userData: state.auth.userData,
});

export default connect(mapStateToProps)(List);
