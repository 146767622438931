import React, {useEffect, useRef, useState} from 'react';
import styles from './Login.module.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import HTTPService from '../../service/HTTPService';
import {logNetworkError} from '../../utils/error';
import {setRefreshToken} from '../../service/LocalStorage';
import {connect} from 'react-redux';
import {setUserData} from '../../store/action';
import {FORM} from '../../const/routes';
import {useNavigate} from 'react-router-dom';

const Login = ({userData, setUserData}) => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [qrCodeURL, setQrCodeURL] = useState('');
    const [code, setCode] = useState('');
    const [showTokenInput, setShowTokenInput] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (userData) {
            navigate(FORM);
        }
    }, [navigate, userData]);

    const codeInputRef = useRef();

    const onLoginClickHandler = event => {
        event.preventDefault();
        HTTPService.login(login.trim(), password)
            .then(({data}) => {
                const {accessToken, twoFactorQRCodeURL} = data;
                setQrCodeURL(twoFactorQRCodeURL);
                setShowTokenInput(true);
                codeInputRef.current?.focus();
                HTTPService.setAccessToken(accessToken);
            })
            .catch(err => {
                logNetworkError(err);
                window.alert('Nie udało się zalogować');
            });
    };

    const onVerifyCodeClickHandler = event => {
        event.preventDefault();
        HTTPService.validate2FACode(code.trim())
            .then(({data}) => {
                const {accessToken, refreshToken} = data;
                setRefreshToken(refreshToken);
                HTTPService.setAccessToken(accessToken);
                return HTTPService.getUserData();
            })
            .then(({data}) => {
                setUserData(data);
                navigate(FORM);
            })
            .catch(err => {
                logNetworkError(err);
                window.alert('Nie udało się zalogować');
            });
    };

    return showTokenInput ? (
        <form className={styles.container}>
            {!!qrCodeURL && <img alt={'qr kod'} src={qrCodeURL} />}
            <TextField
                className={styles.textInput}
                onChange={event => setCode(event.target.value)}
                label={'Kod'}
                value={code}
                inputRef={codeInputRef}
            />
            <Button type="submit" onClick={onVerifyCodeClickHandler}>
                Zatwierdź
            </Button>
        </form>
    ) : (
        <form className={styles.container}>
            <TextField
                autoFocus
                className={styles.textInput}
                onChange={event => setLogin(event.target.value)}
                value={login}
                label={'Login'}
            />
            <TextField
                className={styles.textInput}
                onChange={event => setPassword(event.target.value)}
                value={password}
                label={'Hasło'}
                type="password"
            />
            <Button type="submit" onClick={onLoginClickHandler}>
                Zaloguj się
            </Button>
        </form>
    );
};

const mapDispatchToProps = {
    setUserData,
};

const mapStateToProps = state => ({
    userData: state.auth.userData,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
