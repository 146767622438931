// user
export const ME = '/api/v1/user/me';
export const REFRESH = '/api/v1/user/refresh';
export const LOGIN = '/api/v1/user/login';
export const TWO_FACTOR_AUTH = '/api/v1/user/2fa';
export const USERS = '/api/v1/user';
export const USER = id => `/api/v1/user/${id}`;

// register
export const SEARCH = '/api/v1/register/search';
export const COUNT = '/api/v1/register/count';
export const REGISTER = id => `/api/v1/register/${id}`;
export const LOAD_COURTS = '/api/v1/register/load';

// court
export const COURT = id => `/api/v1/court/${id || ''}`;
export const UPDATE_REGISTERS = `/api/v1/court/updateRegisters`;

//changes
export const CHANGES = `/api/v1/changes`;

// list
export const FAVOURITE = `/api/v1/list/favourite`;
export const SEARCH_AND_ADD_TO_LIST = '/api/v1/list/search';
export const HIDDEN = `/api/v1/list/hidden`;
export const LIST = `/api/v1/list`;
export const LIST_BY_ID = id => `/api/v1/list/${id}`;
export const LIST_REGISTER = `/api/v1/list/register`;

// note
export const NOTE = `/api/v1/note`;

// filter
export const FILTER = (id = '') => `/api/v1/filter/${id}`;
