import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
    Button,
    Select,
    MenuItem,
    ListItemText,
    Snackbar,
    Alert,
    debounce,
} from '@mui/material';
import HTTPService from '../../service/HTTPService';
import {logNetworkError} from '../../utils/error';
import styles from './registerTopToolkit.module.css';
import {Checkbox, TextField} from '../index';
import {last} from 'lodash';
import AddNewList from '../../page/List/AddNewList/AddNewList';

const RegisterTopToolkit = ({
    fullRegister,
    isFavourite,
    isHidden,
    switchRegisterVersion,
    lists,
}) => {
    const navigate = useNavigate();
    const {registerId} = useParams();
    const [favourite, setFavourite] = useState(isFavourite);
    const [note, setNote] = useState('');
    const [addNewList, setAddNewList] = useState(false);
    const [hidden, setHidden] = useState(isHidden);
    const [userLists, setUserLists] = useState([]);
    const [selectedLists, setSelectedLists] = useState(lists);
    const [alertVisible, setAlertVisible] = useState(false);
    const [selectOpen, setSelectOpen] = useState(false);

    useEffect(() => {
        Promise.all([
            HTTPService.getUserLists(),
            HTTPService.getNoteByRegisterId(registerId),
        ])
            .then(([userListsResponse, noteResponse]) => {
                setUserLists(userListsResponse.data);
                setNote(noteResponse.data.content);
            })
            .catch(logNetworkError);
    }, [registerId]);

    const addToFavourites = () => {
        const nextValue = !favourite;
        setFavourite(nextValue);
        const method = nextValue
            ? HTTPService.addRegistryToFavourite
            : HTTPService.removeRegisterFromFavourite;

        if (nextValue) {
            const list = userLists.find(l => l.favourite);
            setSelectedLists([...selectedLists, list]);
        } else {
            setSelectedLists(selectedLists.filter(l => !l.favourite));
        }
        method(registerId).catch(logNetworkError);
    };

    const addToHidden = () => {
        const nextValue = !hidden;
        setHidden(nextValue);
        const method = nextValue
            ? HTTPService.addRegistryToHidden
            : HTTPService.removeRegistryFromHidden;

        if (nextValue) {
            const list = userLists.find(l => l.defaultHiddenList);
            setSelectedLists([...selectedLists, list]);
        } else {
            setSelectedLists(selectedLists.filter(l => !l.defaultHiddenList));
        }
        method(registerId).catch(logNetworkError);
    };

    const onSelectedListsChanged = useCallback(
        (listId, allLists = userLists) => {
            if (listId === 'new') {
                setSelectOpen(false);
                setAddNewList(true);
                return;
            }
            const selected = selectedLists.find(l => l.id === listId);
            if (selected) {
                setSelectedLists(selectedLists.filter(l => l.id !== listId));
                HTTPService.removeRegisterFromList(registerId, listId).catch(
                    logNetworkError,
                );
            } else {
                const list = allLists.find(l => l.id === listId);
                setSelectedLists([...selectedLists, list]);
                HTTPService.addRegisterToList(listId, registerId).catch(
                    logNetworkError,
                );
            }
        },
        [selectedLists, userLists, registerId],
    );

    const onCreateNewList = useCallback(
        newList => {
            if (newList) {
                const newUserLists = [...userLists, newList];
                setUserLists(newUserLists);
                onSelectedListsChanged(newList.id, newUserLists);
            }
            setAddNewList(false);
        },
        [onSelectedListsChanged, userLists],
    );

    const onNoteChanged = useMemo(
        () =>
            debounce(newNote => {
                setAlertVisible(false);
                HTTPService.updateNote(registerId, newNote)
                    .then(() => setAlertVisible(true))
                    .catch(logNetworkError);
            }, 500),
        [registerId],
    );

    return (
        <div className={styles.container}>
            <Snackbar
                onClose={() => setAlertVisible(false)}
                autoHideDuration={5000}
                open={alertVisible}
            >
                <Alert>Zapisano notatkę</Alert>
            </Snackbar>
            <div className={styles.leftToolkit}>
                <Button variant="contained" onClick={switchRegisterVersion}>
                    Zmień typ księgi na {fullRegister ? 'aktualną' : 'pełną'}
                </Button>
                <Button
                    style={{marginLeft: 10}}
                    variant="contained"
                    onClick={addToFavourites}
                >
                    {favourite ? 'Usuń z ulubionych' : 'Dodaj do ulubionych'}
                </Button>
                <Button
                    style={{marginLeft: 10}}
                    variant="contained"
                    onClick={addToHidden}
                >
                    {hidden ? 'Odkryj' : 'Ukryj'}
                </Button>
                <Select
                    className={styles.select}
                    multiple
                    open={selectOpen}
                    onOpen={() => setSelectOpen(true)}
                    onClose={() => setSelectOpen(false)}
                    id="list"
                    onChange={event =>
                        onSelectedListsChanged(last(event.target.value))
                    }
                    value={selectedLists}
                    renderValue={lists =>
                        lists.map(({title}) => title).join(', ')
                    }
                >
                    {userLists.map(list => (
                        <MenuItem key={list.id} value={list.id}>
                            <Checkbox
                                checked={selectedLists.some(
                                    ({id}) => id === list.id,
                                )}
                            />
                            <ListItemText primary={list.title} />
                        </MenuItem>
                    ))}
                    <MenuItem value={'new'}>
                        <ListItemText primary={'Dodaj nową listę'} />
                    </MenuItem>
                </Select>
                <AddNewList
                    open={addNewList}
                    onCreateNewList={onCreateNewList}
                />
                {window.history.state?.idx > 0 && (
                    <Button
                        style={{marginLeft: 20}}
                        variant="contained"
                        onClick={() => navigate(-1)}
                    >
                        Powrót
                    </Button>
                )}
            </div>
            <TextField
                style={{backgroundColor: '#d5d5d5', width: 400}}
                multiline
                label={'notatka'}
                value={note}
                onChange={event => {
                    setNote(event.target.value);
                    onNoteChanged(event.target.value);
                }}
            />
        </div>
    );
};

export default RegisterTopToolkit;
