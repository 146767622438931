import {createReducer} from '../../utils/storeUtils';
import {SET_USER_DATA} from '../action/actionTypes';

const initialState = {
    userData: null,
};

const actionHandlers = {
    [SET_USER_DATA]: (state, action) => ({
        ...state,
        userData: action.userData,
    }),
};

export default createReducer(initialState, actionHandlers);
