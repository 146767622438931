import React from 'react';
import {DateRangePicker, Select, TextField} from '../index';
import {useSelector} from 'react-redux';

const entryBasisTypes = [
    {value: 'notarialAct', label: 'akt notarialny'},
    {value: 'administrativeDecision', label: 'decyzja administracyjna'},
    {value: 'courtDecision', label: 'orzeczenie sądu'},
    {value: 'differentBasis', label: 'inna podstawa'},
];

const fieldMapper = {
    notarialAct: {
        title: 'tytuł aktu',
        signature: 'numer rep A',
        date: 'data sporządzenia',
        author: 'Imię notariusza',
        headquarters: 'siedziba',
    },
    administrativeDecision: {
        title: 'przedmiot decyzji',
        signature: 'numer decyzji',
        date: 'data wydania decyzji',
        author: 'oznaczenie organu',
        headquarters: 'siedziba organu',
    },
    courtDecision: {
        title: 'rodzaj i przedmiot orzeczenia',
        signature: 'sygnatura akt',
        date: 'data wydania orzeczenia',
        author: 'nazwa sądu',
        headquarters: 'siedziba sądu',
    },
    differentBasis: {
        title: 'wskazanie podstawy',
        signature: 'sygnatura',
        date: 'data wydania',
        author: 'wystawca',
    },
};

const EntryBasis = ({
    entryBasisType,
    setEntryBasisType,
    entryBasis,
    onEntryBasisFieldChanged,
}) => {
    const user = useSelector(state => state.auth.userData);

    return (
        <>
            <Select
                title={'Typ podstawy'}
                value={entryBasisType}
                id={'entryBasisType'}
                onChange={setEntryBasisType}
                items={entryBasisTypes}
            />
            <div />
            <div />
            {entryBasisType && (
                <>
                    <TextField
                        value={entryBasis.title}
                        id={'title'}
                        label={fieldMapper[entryBasisType].title}
                        onChange={onEntryBasisFieldChanged}
                    />
                    <TextField
                        value={entryBasis.signature}
                        id={'signature'}
                        label={fieldMapper[entryBasisType].signature}
                        onChange={onEntryBasisFieldChanged}
                    />
                    <DateRangePicker
                        id={'date'}
                        value={entryBasis.date}
                        onChange={(value, id) =>
                            onEntryBasisFieldChanged({target: {value, id}})
                        }
                        label={fieldMapper[entryBasisType].date}
                    />
                    {(entryBasisType !== 'notarialAct' || user.superuser) && (
                        <TextField
                            value={entryBasis.author}
                            id={'author'}
                            label={fieldMapper[entryBasisType].author}
                            onChange={onEntryBasisFieldChanged}
                        />
                    )}
                    {fieldMapper[entryBasisType].headquarters && (
                        <TextField
                            value={entryBasis.headquarters}
                            id={'headquarters'}
                            label={fieldMapper[entryBasisType].headquarters}
                            onChange={onEntryBasisFieldChanged}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default EntryBasis;
