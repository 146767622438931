import React from 'react';
import {Checkbox, RangeField, TextField} from '../../../component';

const chapterIV = ({
    matchQuery,
    onQueryMatchFieldChangeBuilder,
    containsAnything,
    onContainsAnythingChange,
}) => (
    <>
        <Checkbox
            checked={containsAnything}
            onChange={onContainsAnythingChange}
            id={'IVcontainsAnything'}
            label={'Posiada jakikolwiek wpis'}
        />
        <TextField
            label={'Rodzaj hipoteki'}
            id={'mortgageType'}
            onChange={onQueryMatchFieldChangeBuilder}
            value={matchQuery.mortgageType}
        />
        <TextField
            label={'Inne informacje'}
            id={'mortgageInformation'}
            onChange={onQueryMatchFieldChangeBuilder}
            value={matchQuery.mortgageInformation}
        />
        <RangeField id={'mortgageValue'} label={'Suma'} />
        <TextField
            id={'mortgageCurrency'}
            onChange={onQueryMatchFieldChangeBuilder}
            label={'Waluta'}
            value={matchQuery.mortgageCurrency}
        />
        <TextField
            label={'Wierzytelność'}
            id={'claim'}
            onChange={onQueryMatchFieldChangeBuilder}
            value={matchQuery.claim}
        />
        <TextField
            label={'Stosunek prawny'}
            id={'mortgageLegalRelationship'}
            onChange={onQueryMatchFieldChangeBuilder}
            value={matchQuery.mortgageLegalRelationship}
        />
        <TextField
            label={'Wierzyciel hipoteczny'}
            id={'mortgageOwner'}
            onChange={onQueryMatchFieldChangeBuilder}
            value={matchQuery.mortgageOwner}
        />
        <TextField
            label={'Imiona rodziców wierzyciela'}
            id={'mortgageOwnerParents'}
            onChange={onQueryMatchFieldChangeBuilder}
            value={matchQuery.mortgageOwnerParents}
        />
        <TextField
            label={'PESEL wierzyciela'}
            id={'mortgageOwnerPesel'}
            onChange={onQueryMatchFieldChangeBuilder}
            value={matchQuery.mortgageOwnerPesel}
        />
        <TextField
            label={'REGON wierzyciela'}
            id={'mortgageOwnerRegon'}
            onChange={onQueryMatchFieldChangeBuilder}
            value={matchQuery.mortgageOwnerRegon}
        />
        <TextField
            label={'KRS wierzyciela'}
            id={'mortgageOwnerKRS'}
            onChange={onQueryMatchFieldChangeBuilder}
            value={matchQuery.mortgageOwnerKRS}
        />
        <TextField
            label={'Administrator hipoteki'}
            id={'mortgageAdministrator'}
            onChange={onQueryMatchFieldChangeBuilder}
            value={matchQuery.mortgageAdministrator}
        />
        <TextField
            label={'Siedziba wierzyciela'}
            id={'mortgageOwnerHeadquarters'}
            onChange={onQueryMatchFieldChangeBuilder}
            value={matchQuery.mortgageOwnerHeadquarters}
        />
        <TextField
            label={'Rola wierzyciela'}
            id={'mortgageOwnerRole'}
            onChange={onQueryMatchFieldChangeBuilder}
            value={matchQuery.mortgageOwnerRole}
        />
        <RangeField id={'mortgagesCount'} label={'Ilość hipotek'} />
    </>
);

export default chapterIV;
