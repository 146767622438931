import React, {memo, useCallback} from 'react';
import {TextField} from '../index';
import {DateTime} from 'luxon';

const CustomDatePicker = memo(
    ({label, value, onChange, placeholder, disabled, ...props}) => {
        const onDateChanged = useCallback(
            event => {
                onChange(DateTime.fromFormat(event.target.value, 'yyyy-MM-dd'));
            },
            [onChange],
        );
        return (
            <TextField
                label={label}
                type="date"
                onChange={onDateChanged}
                value={value?.toFormat('yyyy-MM-dd') || ''}
                placeholder={placeholder}
                disabled={disabled}
                {...props}
                InputLabelProps={{
                    ...props.InputLabelProps,
                    shrink: true,
                }}
            />
        );
    },
);

export default CustomDatePicker;
